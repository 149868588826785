import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { useStore } from '../StoreProvider';

export type SanityLocaleString = {
    _type: 'localeString';
    gb?: string;
    es?: string;
};

export type SanityLocaleText = {
    _type: 'localeText';
    gb?: string;
    es?: string;
};

export type SanityLocaleUrl = {
    _type: 'localeUrl';
    gb?: string;
    es?: string;
};

export type SanityLocaleBlockContent = {
    _type: 'localeBlockContent';
    gb?: any[] | null;
    es?: any[] | null;
};

export const defaultLanguageString = 'gb';

const localStorageKey = 'sheDil';

export function readLanguageFromLocalStorageOrDefault() {
    try {
        let localLang = JSON.parse(window.localStorage.getItem(localStorageKey) as any);
        return (localLang && localLang.language) || defaultLanguageString;
    } catch (err: any) {
        if (typeof window !== `undefined`) {
            // Dont print the error if it's "window is undefined"
            console.error('error reading from local storage', err);
        }
        return defaultLanguageString;
    }
}

export function maybeWriteToLocalStorage(selectedLanguage: string) {
    window.localStorage.setItem(localStorageKey, JSON.stringify({ language: selectedLanguage }));
}

export type Language = 'gb' | 'es';
export const languageSelectorOptions = ['GB', 'ES'];

export const toString = (lang: string) => {
    return lang.toUpperCase();
};

export const useLocalizedString = (
    value?: { gb?: string | null; es?: string | null } | null | string
): string => {
    const lang = useStore().language.selectedLanguage;

    if (!value) {
        return '';
    }
    if (typeof value === 'string') {
        return value;
    }
    if (lang === 'es' && typeof value.es === 'string') {
        return value.es;
    } else {
        return value.gb ?? '';
    }
};

export const useLocalizedBlockObject = (value?: SanityLocaleBlockContent | null | []): any[] => {
    const lang = useStore().language.selectedLanguage;

    if (!value) {
        return [];
    }
    if (Array.isArray(value)) {
        return value;
    }
    if (lang === 'es' && Array.isArray(value.es)) {
        return value.es;
    } else {
        return value.gb ?? [];
    }
};

export const I18nStr: FC<{
    children?: { gb?: string | null; es?: string | null } | null | string;
}> = observer(({ children }) => {
    const localizedString = useLocalizedString(children);
    return <>{localizedString}</>;
});

// const mainTitle = useLocalizedString(product.title);

// <I18nText>{footer.footerAttribution}</I18nText>

// <I18nStr>{title}</I18nStr>

// to={`/${lang}/shop/${product.slug?.[lang]}`}
