import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { useStore } from './StoreProvider';
import LoginRequired from './components/auth/LoginRequired';
import Header from './components/Header';
import Footer from './components/Footer';
import Errors from './components/Errors';
import Landing from './pages/Landing';
import InvestigationList from './pages/InvestigationList';
import CreateInvestigation from './pages/CreateInvestigation';
import InvestigationIndex from './pages/InvestigationIndex';
import InvestigationRelation from './pages/InvestigationRelation';
import NoSuchRoute from './pages/NoSuchRoute';
import How from './pages/How';
import CustomPage from './pages/CustomPage';
import Spinner from './components/Spinner';
import Statistics from './pages/Statistics';
import Preferences from "./pages/Preferences";

const SpinnerWhenLoading: React.FC<{}> = observer(({ children }) => {
    let rootStore = useStore();

    if (rootStore.isLoading) {
        return (
            <React.Fragment>
                <Spinner mt={200} />
            </React.Fragment>
        );
    }

    return <React.Fragment>{children}</React.Fragment>;
});

const AppRouter = () => {
    return (
        <Router>
            <div>
                <Header />
                <Errors />

                <Switch>
                    <Route exact path="/">
                        <Landing />
                    </Route>
                    <Route path="/how" exact>
                        <How />
                    </Route>
                    <Route path="/s">
                        <LoggedInRoutes />
                    </Route>
                    <Route path="/content/:slug">
                        <CustomPage />
                    </Route>
                    <Route path="/about" render={() => <Redirect to="/content/about" />} />
                    <Route>
                        <NoSuchRoute />
                    </Route>
                </Switch>

                <Footer />
            </div>
        </Router>
    );
};

function LoggedInRoutes() {
    return (
        <SpinnerWhenLoading>
            <LoginRequired>
                <Switch>
                    <Route path="/s/list" exact>
                        <InvestigationList />
                    </Route>
                    <Route path="/s/content/:slug">
                        <CustomPage />
                    </Route>
                    <Route path="/s/create" exact>
                        <CreateInvestigation />
                    </Route>
                    <Route path="/s/she/:investigationId" exact>
                        <InvestigationIndex />
                    </Route>
                    <Route path="/s/she/:investigationId/r/:relationId">
                        <InvestigationRelation />
                    </Route>
                    <Route path="/s/statistics">
                        <Statistics />
                    </Route>
                    <Route path="/s/preferences">
                        <Preferences />
                    </Route>
                    <Route>
                        <NoSuchRoute />
                    </Route>
                </Switch>
            </LoginRequired>
        </SpinnerWhenLoading>
    );
}

export default AppRouter;
