import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components';
import { colors } from '../styles';
import ReactFlagsSelect from 'react-flags-select';

import { languageSelectorOptions } from './localization';
import { useStore } from '../StoreProvider';

export const LangSelect: React.FC<{ darkMode?: boolean }> = observer(({ darkMode }) => {
    const lang = useStore().language;

    return (
        <LangSelector darkMode={darkMode}>
            <ReactFlagsSelect
                selected={lang.selectedLanguage.toUpperCase()}
                onSelect={code => lang.setLanguage(code.toLowerCase())}
                showSelectedLabel={false}
                countries={languageSelectorOptions}
                showOptionLabel={true}
                fullWidth={false}
                optionsSize={14}
                customLabels={{ GB: 'English', ES: 'Español' }}
            />
        </LangSelector>
    );
});

const LangSelector = styled.div<{ darkMode?: boolean }>`
    ul {
        background: ${({ darkMode }) => (darkMode ? colors.black : 'inherit')};
        white-space: auto;
    }
    margin: 0 10px 0 20px;
    letter-spacing: 0;
    background: ${({ darkMode }) => (darkMode ? colors.black : 'inherit')};
    border: none;
    white-space: auto;
`;
