import { types, flow, Instance, getParentOfType } from 'mobx-state-tree';

import shedil from '../api/shedil';
import { Industry } from './TaskStore';
import { RootStore } from './RootStore';

export const TaskStatus = types.model('TaskStatus', {
    id: types.identifier,
    done: types.boolean,
    notApplicable: types.boolean,
    notes: types.string,
    pending: false,
});

export const RelationStatus = types.model('RelationStatus', {
    id: types.identifier,
    notApplicable: types.boolean,
    pending: false,
});

export const Investigation = types
    .model('Investigation', {
        id: types.identifier,
        active: types.boolean,
        owner: types.string,
        createdAt: types.Date,
        country: types.optional(types.string, ''),
        name: types.string,
        industry: types.reference(Industry),
        taskStatus: types.map(TaskStatus),
        relationStatus: types.map(RelationStatus),
        pending: false,
        isDeleting: false,
    })
    .actions(self => ({
        setNameAndActive: flow(function* setNameAndActive(name: string, active: boolean) {
            let lastName = self.name;
            let lastActive = self.active;
            try {
                self.pending = true;
                self.name = name;
                self.active = active;

                yield shedil.put(`/v1/investigation/${self.id}`, { name, active });
            } catch (err: any) {
                self.name = lastName;
                self.active = lastActive;
                getParentOfType(self, RootStore).setError(err, 'setNameAndActive');
            } finally {
                self.pending = false;
            }
        }),
        setDone: flow(function* setDone(taskKey: string, done: boolean) {
            if (!self.taskStatus.has(taskKey)) {
                self.taskStatus.put({
                    id: taskKey,
                    done: false,
                    notApplicable: false,
                    notes: '',
                    pending: true,
                });
            }
            let taskStatus = self.taskStatus.get(taskKey)!;
            let lastDone = taskStatus.done;
            try {
                taskStatus.pending = true;
                taskStatus.done = done;

                yield shedil.patch(`/v1/investigation/${self.id}/task/${taskKey}`, {
                    done,
                });
            } catch (err: any) {
                taskStatus.done = lastDone;
                getParentOfType(self, RootStore).setError(err, 'setDone');
            } finally {
                taskStatus.pending = false;
            }
        }),
        setNotApplicable: flow(function* setNotApplicable(taskKey: string, notApplicable: boolean) {
            if (!self.taskStatus.has(taskKey)) {
                self.taskStatus.put({
                    id: taskKey,
                    done: false,
                    notApplicable: false,
                    notes: '',
                    pending: true,
                });
            }

            let taskStatus = self.taskStatus.get(taskKey)!;
            let lastDone = taskStatus.done;
            let lastNa = taskStatus.notApplicable;

            try {
                taskStatus.pending = true;
                taskStatus.notApplicable = notApplicable;
                if (taskStatus.notApplicable) {
                    taskStatus.done = false;
                }

                let update = notApplicable ? { notApplicable, done: false } : { notApplicable };
                yield shedil.patch(`/v1/investigation/${self.id}/task/${taskKey}`, update);
            } catch (err: any) {
                taskStatus.done = lastDone;
                taskStatus.notApplicable = lastNa;
                getParentOfType(self, RootStore).setError(err, 'setNotApplicable');
            } finally {
                taskStatus.pending = false;
            }
        }),
        setTaskNotes: flow(function* setTaskNotes(taskKey: string, notes: string) {
            if (!self.taskStatus.has(taskKey)) {
                self.taskStatus.put({
                    id: taskKey,
                    done: false,
                    notApplicable: false,
                    notes: '',
                    pending: true,
                });
            }

            let taskStatus = self.taskStatus.get(taskKey)!;

            try {
                taskStatus.pending = true;
                taskStatus.notes = notes;

                yield shedil.patch(`/v1/investigation/${self.id}/task/${taskKey}`, {
                    notes,
                });
            } catch (err: any) {
                getParentOfType(self, RootStore).setError(err, 'setTaskNotes');
            } finally {
                taskStatus.pending = false;
            }
        }),
        setRelationNotApplicable: flow(function* setRelationNotApplicable(
            relationKey: string,
            notApplicable: boolean
        ) {
            if (!self.relationStatus.has(relationKey)) {
                self.relationStatus.put({
                    id: relationKey,
                    notApplicable: false,
                    pending: true,
                });
            }
            let relationStatus = self.relationStatus.get(relationKey)!;

            let lastNa = relationStatus.notApplicable;

            try {
                relationStatus.pending = true;
                relationStatus.notApplicable = notApplicable;
                yield shedil.put(`/v1/investigation/${self.id}/relation/${relationKey}`, {
                    notApplicable,
                });
            } catch (err: any) {
                relationStatus.notApplicable = lastNa;
                getParentOfType(self, RootStore).setError(err, 'setRelationNotApplicable');
            } finally {
                relationStatus.pending = false;
            }
        }),
    }));

export interface ITaskStatus extends Instance<typeof TaskStatus> {}
export interface IRelationStatus extends Instance<typeof RelationStatus> {}
export interface IInvestigation extends Instance<typeof Investigation> {}

export const InvestigationStore = types
    .model('InvestigationStore', {
        isLoading: false,
        userId: types.maybe(types.string),
        investigations: types.map(Investigation),
    })
    .views(self => ({
        get activeInvestigationsByIndustry() {
            let result: Map<string, IInvestigation[]> = new Map();
            self.investigations.forEach(investigation => {
                if (investigation.active && !investigation.isDeleting) {
                    if (result.get(investigation.industry._id) === undefined) {
                        result.set(investigation.industry._id, []);
                    }
                    result.get(investigation.industry._id)!.push(investigation);
                }
            });

            return result;
        },
        get inActiveInvestigationsByIndustry() {
            let result: Map<string, IInvestigation[]> = new Map();
            self.investigations.forEach(investigation => {
                if (!investigation.active && !investigation.isDeleting) {
                    if (result.get(investigation.industry._id) === undefined) {
                        result.set(investigation.industry._id, []);
                    }
                    result.get(investigation.industry._id)!.push(investigation);
                }
            });

            return result;
        },
        get isPending() {
            for (let investigation of self.investigations.values()) {
                if (investigation.pending) {
                    return true;
                }
                for (let taskStatus of investigation.taskStatus.values()) {
                    if (taskStatus.pending) {
                        return true;
                    }
                }
                for (let relationStatus of investigation.relationStatus.values()) {
                    if (relationStatus.pending) {
                        return true;
                    }
                }
            }
            return false;
        },
    }))
    .actions(self => ({
        addTaskStatus(investigationId: string, taskStatus: ITaskStatus) {
            self.investigations.get(investigationId)!.taskStatus.put(taskStatus);
        },
        addRelationStatus(investigationId: string, relationStatus: IRelationStatus) {
            self.investigations.get(investigationId)!.relationStatus.put(relationStatus);
        },
        removeInvestigation: flow(function* (investigationId: string) {
            let investigation = self.investigations.get(investigationId);
            try {
                if (investigation) {
                    investigation.pending = true;
                    investigation.isDeleting = true;

                    yield shedil.delete(`/v1/investigation/${investigationId}`);

                    self.investigations.delete(investigationId);
                }
            } catch (err: any) {
                if (investigation) investigation.isDeleting = false;
                getParentOfType(self, RootStore).setError(err, 'setNameAndActive');
            }
        }),
    }))
    .actions(self => ({
        clearData() {
            self.investigations.clear();
        },
        loadData: flow(function* loadData(userId) {
            try {
                self.userId = userId;
                self.isLoading = true;

                let res = yield shedil.get('/v1/investigation');

                res.data.forEach((investigation: any) => {
                    self.investigations.put({
                        id: investigation.id,
                        owner: userId,
                        active: investigation.active,
                        createdAt: new Date(investigation.createdAt),
                        country: investigation.country || '',
                        name: investigation.name,
                        industry: investigation.industry,
                    });

                    investigation.taskStatus.forEach((taskStatus: any) => {
                        self.addTaskStatus(investigation.id, {
                            id: taskStatus.taskId,
                            done: taskStatus.done,
                            notApplicable: taskStatus.notApplicable,
                            notes: taskStatus.notes,
                            pending: false,
                        });
                    });

                    investigation.relationStatus.forEach((relationStatus: any) => {
                        self.addRelationStatus(investigation.id, {
                            id: relationStatus.relationId,
                            notApplicable: relationStatus.notApplicable,
                            pending: false,
                        });
                    });
                });
                self.isLoading = false;
            } catch (err: any) {
                getParentOfType(self, RootStore).setError(err, 'InvestigationStoreLoadData');
            }
        }),
        createInvestigation: flow(function* createInvestigation({
            industry,
            name,
        }: {
            industry: string;
            name: string;
        }) {
            if (self.userId === undefined) {
                return;
            }
            try {
                let res = yield shedil.post('/v1/investigation', {
                    industry,
                    name,
                });
                let investigation = res.data;

                self.investigations.put({
                    id: investigation.id,
                    owner: self.userId,
                    active: investigation.active,
                    createdAt: new Date(investigation.createdAt),
                    name: investigation.name,
                    country: investigation.country || '',
                    industry: investigation.industry,
                });

                return investigation.id as string;
            } catch (err: any) {
                getParentOfType(self, RootStore).setError(err, 'createInvestigation');
            }
        }),
    }));
