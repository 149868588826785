import 'intersection-observer';

import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';

import App from './App';
import { StoreProvider } from './StoreProvider';
import './api/firebase';
import { RootStore } from './stores/RootStore';

if (process.env.NODE_ENV === 'production') {
    Sentry.init({ dsn: 'https://1c4ae84f74e2474ca8c689efdeba4cdc@sentry.io/1476379' });
}

const store = RootStore.create();

store.loadData();

ReactDOM.render(
    <StoreProvider value={store}>
        <App />
    </StoreProvider>,
    document.getElementById('root')
);
