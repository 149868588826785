import React from 'react';
import PageWrapper from '../components/PageWrapper';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';

const NoSuchRoute = () => {
    return (
        <PageWrapper>
            <ScrollToTopOnMount />
            404 - Can't find what you are looking for
        </PageWrapper>
    );
};

export default NoSuchRoute;
