import React, { MouseEvent } from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import firebase from 'firebase/compat/app';

import { buttonReset, linkReset, pageWidth, h2, colors, button } from '../styles';
import { useStore } from '../StoreProvider';
import shedilLogo from '../img/SheDiL_2_fixed_cropped.png';
import shedilLogoInverted from '../img/SheDiL_2_fixed_cropped_inverted.png';
import { LangSelect } from '../i18n/langSelect';
import { I18nStr } from '../i18n/localization';

// import * as types from 'styled-components/cssprop'; // Enable the css prop

const Nav = styled.nav<{ inverted: boolean }>`
    padding: 0 0 17px;
    position: sticky;
    z-index: 2;
    top: 0;
    right: 0;
    left: 0;

    ${({ inverted }) =>
        inverted
            ? `
        color: ${colors.neutral};
        background: ${colors.black};
    `
            : `

    `};

    padding: 24px 0 8px;

    .mainlink {
        display: block;
        ${linkReset};
        font-weight: 300;
        font-size: 16px;
        line-height: 32px;

        img {
            height: 16px;
        }

        @media (min-width: 600px) {
            font-size: 24px;

            img {
                display: block;
            }
        }
    }

    .firstOnRight {
        @media (min-width: 400px) {
            margin-left: auto;
        }
    }

    .addRightMargin {
        @media (min-width: 400px) {
            margin-right: 32px;
        }
    }

    a {
        font-weight: 500;
    }

    li {
    }

    button {
        ${buttonReset};
        white-space: nowrap;
        font-weight: 500;
        display: flex;
        align-items: center;
        svg {
            margin-left: 8px;
        }
    }

    .headerlink {
        ${linkReset};
    }

    .name {
        ${h2};
        color: #e14f11;
        @media (min-width: 400px) {
            margin-left: 16px;
        }
    }
`;

const NavUl = styled.ul`
    padding: 0 16px;
    margin: 0 auto;
    max-width: ${pageWidth};
    display: flex;
    align-items: center;
    list-style: none;
    justify-content: space-between;
    @media (min-width: 400px) {
        justify-content: flex-start;
    }
    @media (min-width: 600px) {
        padding: 0 24px;
    }
`;

const StartShedil = styled(Link)`
    ${linkReset};
    display: block;
    white-space: nowrap;

    @media (min-width: 600px) {
        ${button};
        margin-top: -38px;
        padding-top: 38px;
    }
    @media (min-width: 400px) {
        margin-right: 38px;
        margin-left: 38px;
    }
`;

const logout = (ev: MouseEvent) => {
    ev.preventDefault();
    firebase.auth().signOut();
};

const LoggedInStatus = observer(() => {
    let rootStore = useStore();
    let authStore = rootStore.authStore;
    let texts = rootStore.generalTextsStore.generalTexts;

    if (authStore.isLoading) {
        return <div>&nbsp;</div>;
    }

    if (authStore.currentUser !== undefined) {
        return (
            <button onClick={logout}>
                <I18nStr>{texts.logOut}</I18nStr> <LogoutIcon />
            </button>
        );
    }

    return (
        <Link className="headerlink" to="/s/list">
            <I18nStr>{texts.logIn}</I18nStr>
        </Link>
    );
});

const HeaderOutside = observer(() => {
    let texts = useStore().generalTextsStore.generalTexts;
    return (
        <Nav inverted={false}>
            <NavUl>
                <li>
                    <Link className="mainlink" to="/">
                        <img alt="SheDil" src={shedilLogo} style={{ height: '32px' }} />
                    </Link>
                </li>
                <li className="firstOnRight">
                    <Link className="headerlink" to="/content/about">
                        <I18nStr>{texts.about}</I18nStr>
                    </Link>
                </li>
                <li>
                    <StartShedil to="/s/list">
                        <I18nStr>{texts.access}</I18nStr>
                    </StartShedil>
                </li>
                <li>
                    <LoggedInStatus />
                </li>
                <li>
                    <LangSelect />
                </li>
            </NavUl>
        </Nav>
    );
});

const HeaderInside = observer(() => {
    const rootStore = useStore();
    let texts = rootStore.generalTextsStore.generalTexts;
    let statsAccess = rootStore.statStore.access;
    return (
        <Nav inverted={true}>
            <NavUl>
                <li>
                    <Link className="mainlink" to="/">
                        <img alt="SheDil" src={shedilLogoInverted} />
                    </Link>
                </li>
                {statsAccess && (
                    <li className="firstOnRight addRightMargin">
                        <Link className="headerlink" to="/s/statistics">
                            Statistics
                        </Link>
                    </li>
                )}
                <li className={statsAccess ? 'addRightMargin' : 'firstOnRight addRightMargin'}>
                    <Link className="headerlink" to="/s/list">
                        <I18nStr>{texts.access}</I18nStr>
                    </Link>
                </li>
                <li className="addRightMargin">
                    <Link className="headerlink" to="/s/content/about">
                        <I18nStr>{texts.about}</I18nStr>
                    </Link>
                </li>
                {
                    rootStore.authStore.currentUser && (
                        <li className="addRightMargin">
                            <Link className="headerlink" to="/s/preferences">
                                <I18nStr>{texts.preferences}</I18nStr>
                            </Link>
                        </li>
                  )
                }
                <li>
                    <LoggedInStatus />
                </li>
                <li>
                    <LangSelect darkMode />
                </li>
            </NavUl>
        </Nav>
    );
});

const Header = observer(() => (
    <Switch>
        <Route path="/s" component={HeaderInside} />
        <Route component={HeaderOutside} />
    </Switch>
));

export default Header;

function LogoutIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 16 16"
        >
            <path
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 14H3.333A1.334 1.334 0 012 12.667V3.333A1.333 1.333 0 013.333 2H6M10.667 11.333L14 8l-3.333-3.333M14 8H6"
            ></path>
        </svg>
    );
}
