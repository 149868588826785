import { types, getParentOfType } from 'mobx-state-tree';
import {
    maybeWriteToLocalStorage,
    readLanguageFromLocalStorageOrDefault,
} from '../i18n/localization';
import { RootStore } from './RootStore';

export const defaultLanguage = {
    selectedLanguage: readLanguageFromLocalStorageOrDefault(),
    pending: false,
};

export const SelectedLanguage = types
    .model('SelectedLanguage', { selectedLanguage: types.string, pending: types.boolean })
    .actions(self => ({
        setLanguage(lang: string) {
            let lastLang = self.selectedLanguage;
            try {
                self.pending = true;
                self.selectedLanguage = lang;
            } catch (err: any) {
                self.selectedLanguage = lastLang;
                getParentOfType(self, RootStore).setError(err, 'setLanguage');
            } finally {
                maybeWriteToLocalStorage(lang);
                self.pending = false;
            }
        },
    }));
