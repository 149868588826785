import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { Menu, MenuButton, MenuList, MenuItem, MenuLink } from '@reach/menu-button';
import { AlertDialog, AlertDialogLabel, AlertDialogDescription } from '@reach/alert-dialog';
import { useHistory } from 'react-router-dom';
import '@reach/menu-button/styles.css';
import { Dialog } from '@reach/dialog';
import '@reach/dialog/styles.css';
import { button, buttonReset, buttonText, colors, h2, inputStyle, p2 } from '../styles';
import { apiUrl } from '../api/shedil';
import { IInvestigation } from '../stores/InvestigationStore';
import { useStore } from '../StoreProvider';
import { observer } from 'mobx-react-lite';
import { I18nStr } from '../i18n/localization';

export const ShedilMenu: React.FC<{
    investigation: IInvestigation;
    redirectOnDelete: boolean;
}> = observer(({ investigation, redirectOnDelete }) => {
    const [showNameDialog, setShowNameDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const rootStore = useStore();
    const texts = rootStore.generalTextsStore.generalTexts;

    return (
        <>
            <Menu>
                <StyledMenuButton>
                    <MenuIcon />
                </StyledMenuButton>
                <StyledMenuList>
                    <MenuLink href={`${apiUrl}/v1/investigation/${investigation.id}/report.xlsx`}>
                        <I18nStr>{texts.download}</I18nStr> <DownloadIcon />
                    </MenuLink>
                    <MenuItem onSelect={() => setShowNameDialog(true)}>
                        <I18nStr>{texts.renameSheDil}</I18nStr> <RenameIcon />
                    </MenuItem>
                    <MenuItem
                        onSelect={() =>
                            investigation.setNameAndActive(
                                investigation.name,
                                !investigation.active
                            )
                        }
                    >
                        {investigation.active ? (
                            <I18nStr>{texts.setSheDilAsInactive}</I18nStr>
                        ) : (
                            <I18nStr>{texts.setSheDilAsActive}</I18nStr>
                        )}
                        {investigation.active ? <SetInactiveIcon /> : <SetActiveIcon />}
                    </MenuItem>
                    <MenuItem
                        onSelect={() => {
                            setShowDeleteDialog(true);
                        }}
                    >
                        <I18nStr>{texts.deleteSheDil}</I18nStr> <DeleteIcon />
                    </MenuItem>
                </StyledMenuList>
            </Menu>
            <ChangeNameDialog
                investigation={investigation}
                isOpen={showNameDialog}
                onDismiss={() => setShowNameDialog(false)}
            />
            <DeleteDialog
                investigation={investigation}
                isOpen={showDeleteDialog}
                close={() => setShowDeleteDialog(false)}
                redirectOnDelete={redirectOnDelete}
            />
        </>
    );
});

const StyledMenuButton = styled(MenuButton)`
    ${buttonReset};
    margin: 0 8px;
    color: #130511;

    &[aria-expanded='true'] {
        color: ${colors.actionBlue};
    }
`;

const StyledMenuList = styled(MenuList)`
    && {
        padding: 0;
        ${buttonText};
        border: none;
        box-shadow: 0px 0px 17px rgba(19, 5, 17, 0.1);
    }

    [data-reach-menu-item] {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 24px;
        position: relative;

        &:after {
            position: absolute;
            content: '';
            border-bottom: 1px solid #d0cdcf;
            bottom: 0px;
            left: 8px;
            right: 8px;
        }

        svg {
            margin-left: 24px;
            min-width: 24px;
        }

        &[data-selected] {
            &:after {
                border-color: ${colors.actionBlueHover};
            }
            background: ${colors.actionBlueHover};
        }
    }

    [data-reach-menu-item] div:last-of-type {
        &:after {
            border-bottom: none;
        }
    }
`;

const DeleteDialog: FC<{
    investigation: IInvestigation;
    isOpen: boolean;
    close: () => void;
    redirectOnDelete: boolean;
}> = ({ investigation, isOpen, close, redirectOnDelete }) => {
    const rootStore = useStore();
    const investigationStore = rootStore.investigationStore;
    const text = rootStore.generalTextsStore.generalTexts;

    const cancelRef = React.useRef<HTMLButtonElement | null>(null);
    const history = useHistory();
    return (
        <StyledAlertDialog leastDestructiveRef={cancelRef} isOpen={isOpen}>
            <StyledAlertDialogLabel>
                <I18nStr>{text.deleteConfirmationHeader}</I18nStr>
            </StyledAlertDialogLabel>
            <AlertDialogDescription>
                <I18nStr>{text.deleteConfirmation}</I18nStr>
            </AlertDialogDescription>
            <StyledDeleteButtonsDiv>
                <StyledCancelButton ref={cancelRef} onClick={close}>
                    <I18nStr>{text.close}</I18nStr>
                </StyledCancelButton>
                <StyledActionButton
                    onClick={() => {
                        investigationStore.removeInvestigation(investigation.id);
                        if (redirectOnDelete) {
                            history.push('/s/list');
                        }
                    }}
                >
                    <I18nStr>{text.deleteSheDil}</I18nStr>
                </StyledActionButton>
            </StyledDeleteButtonsDiv>
        </StyledAlertDialog>
    );
};

const StyledAlertDialog = styled(AlertDialog)`
    max-width: 470px;
    width: calc(100% - 48px);
    padding: 32px 8px;
    margin: 15vh auto;
    border-radius: 4px;
    box-shadow: 0px 0px 17px rgba(19, 5, 17, 0.1);

    @media (min-width: 600px) {
        padding: 32px 24px;
    }

    button {
        font-size: 16px;
        font-weight: 500;
        margin-top: 16px;
        @media (min-width: 600px) {
            margin-top: 0;
        }
    }
`;

const StyledAlertDialogLabel = styled(AlertDialogLabel)`
    ${h2};
    margin-bottom: 24px;
`;

const StyledDeleteButtonsDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
`;

const StyledCancelButton = styled.button`
    ${buttonReset}
    padding: 0 17px;
`;

const StyledActionButton = styled.button`
    ${button};
`;

const ChangeNameDialog: FC<{
    investigation: IInvestigation;
    isOpen: boolean;
    onDismiss: () => void;
}> = observer(({ investigation, isOpen, onDismiss }) => {
    const rootStore = useStore();
    const text = rootStore.generalTextsStore.generalTexts;
    const [name, setName] = useState(investigation.name);
    return (
        <StyledDialog aria-labelledby="change-name-title" isOpen={isOpen} onDismiss={onDismiss}>
            <StyledForm
                onSubmit={ev => {
                    ev.preventDefault();
                    investigation.setNameAndActive(name, investigation.active);
                    onDismiss();
                }}
            >
                <label id="change-name-title">
                    <I18nStr>{text.renameSheDil}</I18nStr>
                    <input value={name} onChange={e => setName(e.target.value)} />
                </label>
                <StyledChangeNameButtonsDiv>
                    <StyledCancelButton type="button" onClick={onDismiss}>
                        <I18nStr>{text.cancel}</I18nStr>
                    </StyledCancelButton>
                    <StyledActionButton type="submit">
                        <I18nStr>{text.done}</I18nStr>
                    </StyledActionButton>
                </StyledChangeNameButtonsDiv>
            </StyledForm>
        </StyledDialog>
    );
});

const StyledChangeNameButtonsDiv = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 80px;

    button {
        width: 121px;
    }
`;

const StyledDialog = styled(Dialog)`
    max-width: 470px;
    width: calc(100% - 48px);
    padding: 32px 8px;
    margin: 15vh auto;
    border-radius: 4px;
    box-shadow: 0px 0px 17px rgba(19, 5, 17, 0.1);

    @media (min-width: 600px) {
        padding: 32px 24px;
    }
`;

const StyledForm = styled.form`
    width: 100%;
    margin-top: 70px;
    input {
        display: block;
        width: 100%;
        max-width: 469px;
        ${inputStyle};
        margin-top: 10px;
    }

    label {
        display: block;
        ${p2};
    }

    button {
        font-size: 16px;
        font-weight: 500;
        margin-top: 16px;
        width: 100px;
        @media (min-width: 600px) {
            margin-left: 16px;
            margin-top: 0;
        }
    }
`;

const MenuIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
            aria-label="Actions"
        >
            <path
                stroke="currentcolor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 13a1 1 0 100-2 1 1 0 000 2zM19 13a1 1 0 100-2 1 1 0 000 2zM5 13a1 1 0 100-2 1 1 0 000 2z"
            ></path>
        </svg>
    );
};

const DownloadIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
            aria-hidden
        >
            <path
                stroke="currentcolor"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 15v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4M7 10l5 5 5-5M12 15V3"
            ></path>
        </svg>
    );
};

const RenameIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 16 16"
            aria-hidden
        >
            <path
                stroke="currentcolor"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M11.333 2a1.884 1.884 0 013.22 1.333A1.887 1.887 0 0114 4.667l-9 9-3.667 1 1-3.667 9-9z"
            ></path>
        </svg>
    );
};

const SetInactiveIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
            aria-hidden
        >
            <path
                stroke="currentcolor"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 5H8a7 7 0 000 14h8a7 7 0 100-14z"
            ></path>
            <path fill="#E14F11" d="M8 15a3 3 0 100-6 3 3 0 000 6z"></path>
        </svg>
    );
};

const SetActiveIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 5H8a7 7 0 000 14h8a7 7 0 100-14z"
            ></path>
            <path fill="#A5DEA4" d="M16 15a3 3 0 100-6 3 3 0 000 6z"></path>
        </svg>
    );
};

const DeleteIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
            aria-hidden
        >
            <path
                stroke="currentcolor"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3 6h18M8 6V4a2 2 0 012-2h4a2 2 0 012 2v2m3 0v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6h14z"
            ></path>
        </svg>
    );
};
