import React, { FC, useState } from 'react';
import * as BlockContent from '@sanity/block-content-to-react';
import styled from 'styled-components';
import { BlockContent as TBlockContent } from '../stores/TaskStore';
import { h3, colors, buttonReset } from '../styles';
import { I18nStr } from '../i18n/localization';
import { useStore } from '../StoreProvider';
import { observer } from 'mobx-react-lite';

// import * as types from 'styled-components/cssprop'; // Enable the css prop

export const ExpandableRichText: FC<{ content: TBlockContent }> = observer(({ content }) => {
    const plainText = toPlainText(content);
    const rootStore = useStore();
    const texts = rootStore.generalTextsStore.generalTexts;

    const [expanded, setExpanded] = useState(false);

    return (
        <StyledWrapperDiv>
            {expanded ? (
                <>
                    <BlockContent blocks={content} />
                    <StyledOpenButton onClick={() => setExpanded(false)}>
                        <I18nStr>{texts.readLess}</I18nStr> <ReadLessArrow />
                    </StyledOpenButton>
                </>
            ) : (
                <div>
                    {plainText.substring(0, 100)}...{' '}
                    <StyledClosedButton onClick={() => setExpanded(true)}>
                        <I18nStr>{texts.readMore}</I18nStr> <ReadMoreArrow />
                    </StyledClosedButton>
                </div>
            )}
        </StyledWrapperDiv>
    );
});

const StyledWrapperDiv = styled.div`
    margin-right: 24px;
    h2 {
        ${h3};
    }
    a {
        text-decoration: none;
        font-weight: 500;
        color: ${colors.link};
        &:hover {
            text-decoration: underline;
            color: ${colors.link};
        }
    }
`;

const StyledOpenButton = styled.button`
    ${buttonReset};
    color: ${colors.actionPurple};
    display: block;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;

    svg {
        margin-left: 4px;
    }
`;

const StyledClosedButton = styled.button`
    ${buttonReset};
    color: ${colors.actionPurple};
    display: inline-block;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;

    svg {
        margin-left: 4px;
    }
`;

function toPlainText(blocks: TBlockContent = []) {
    return (
        blocks
            // loop through each block
            .map(block => {
                // if it's not a text block with children,
                // return nothing
                if (block._type !== 'block' || !block.children) {
                    return '';
                }
                // loop through the children spans, and join the
                // text strings
                return block.children.map((child: any) => child.text).join('');
            })
            // join the paragraphs leaving split by two linebreaks
            .join('\n\n')
    );
}

function ReadMoreArrow() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="10"
            fill="none"
            viewBox="0 0 16 10"
            aria-hidden
        >
            <path
                fill="currentcolor"
                fillRule="evenodd"
                d="M0 .773L.111.716a2 2 0 012.424.47l5.087 5.867a.5.5 0 00.756 0l5.087-5.868a2 2 0 012.424-.47L16 .774 8.756 9.129a1 1 0 01-1.512 0L0 .773z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

function ReadLessArrow() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="10"
            fill="none"
            viewBox="0 0 16 10"
            aria-hidden
        >
            <path
                fill="currentcolor"
                fillRule="evenodd"
                d="M0 9.227l.111.057a2 2 0 002.424-.47l5.087-5.867a.5.5 0 01.756 0l5.087 5.868a2 2 0 002.424.47L16 9.226 8.756.871a1 1 0 00-1.512 0L0 9.227z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}
