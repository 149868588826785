import React from 'react';
import styled from 'styled-components';
import PageWrapper from '../components/PageWrapper';
import { useStore } from '../StoreProvider';
import { h2, h3 } from '../styles';
import { IIndustryStats } from '../stores/StatStore';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import NoSuchRoute from './NoSuchRoute';
import { apiUrl } from '../api/shedil';

const Industry: React.FC<{ industry: IIndustryStats }> = ({ industry }) => {
    return (
        <div>
            <InvestigationName>
                {industry.industryName}: {industry.total}
            </InvestigationName>
            <div>Ongoing: {industry.ongoing}</div>
            <div>Inactive: {industry.inactive}</div>
        </div>
    );
};

const Home = () => {
    const rootStore = useStore();
    const statsStore = rootStore.statStore;
    const sortedIndustries = statsStore.stat.sort((a, b) => b.total - a.total);

    if (!statsStore.access) {
        return <NoSuchRoute />;
    }
    return (
        <PageWrapper>
            <ScrollToTopOnMount />
            <h1>Statistics</h1>
            <a href={`${apiUrl}/v1/statistics/report.xlsx`}>Download report as excel</a>
            <Investigations>
                <IndustryHeader>Active Industries</IndustryHeader>
                {sortedIndustries
                    .filter(ind => ind.visible)
                    .map((ind, index) => (
                        <React.Fragment key={ind.id}>
                            <Industry industry={ind} />
                        </React.Fragment>
                    ))}
                <IndustryHeader>Inactive Industries</IndustryHeader>
                {sortedIndustries
                    .filter(ind => !ind.visible)
                    .map((ind, index) => (
                        <React.Fragment key={ind.id}>
                            <Industry industry={ind} />
                        </React.Fragment>
                    ))}
            </Investigations>
        </PageWrapper>
    );
};

const Investigations = styled.div``;

const IndustryHeader = styled.h2`
    ${h2};
    padding-top: 40px;
`;

const InvestigationName = styled.h3`
    ${h3};
    padding-top: 20px;
`;

export default Home;
