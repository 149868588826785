import { css, createGlobalStyle } from 'styled-components';
import { normalize } from 'polished';
import arrowWhite from './img/arrow-white.svg';

export const colors = {
    neutral: '#fff',
    black: '#130511',
    actionPurple: '#6D175F',
    actionPressed: '#410D39',
    actionHover: '#A03892',
    bg: '#FAFAFA',
    // gradProgress: ''
    // gradDismissed: ''
    orange: '#F6B450',
    orangeLight: '#F7C374',
    green: '#A5DEA4',
    hoverGrey: '#ECECEC',
    formGrey: '#E2E2E2',
    unBlue: '#5B92E5',
    inputGrey: '#f1f1f1',
    actionBlue: '#015CE5',
    actionBlueHover: '#3A89FF',
    link: '#003F9D',
};

// Large headings
export const h1 = css`
    font-weight: normal;
    font-size: 40px;
    line-height: 64px;
`;

// Smaller headings
export const h2 = css`
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
`;

// title headings
export const h3 = css`
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-variant: small-caps;
`;

// Most paragraphs
export const p1 = css`
    letter-spacing: 0;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
`;

// Cards and sub-items
export const p2 = css`
    letter-spacing: 0;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
`;

export const link = css`
    letter-spacing: 0;
    font-weight: 500;
    text-decoration: underline;
`;

export const buttonStyle = css`
    letter-spacing: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
`;

export const inputStyle = css`
    background: ${colors.inputGrey};
    border: 1px solid ${colors.inputGrey};
    ${p1};
    padding: 12px 16px;
    border-radius: 2px;

    &:focus {
        border-color: ${colors.actionBlue};
    }
`;

export const colsWidth = (num: number) => `${num * 105 + (num - 1) * 16}px`;

export const cols = (num: number) => css`
    max-width: ${colsWidth(num)};
    width: 100%;
`;

// 1200 px + 24*2 px padding
export const pageWidth = '1248px';
export const innerPageWidth = '712px';

export const GlobalStyle = createGlobalStyle`
    ${normalize()};

    html {
        box-sizing: border-box;
    }
    *, *:before, *:after {
        box-sizing: inherit;
    }

    body {
        font-family: 'Noto Sans TC', sans-serif;
        ${p1};
        margin: 0;
        padding: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: ${colors.black};
        background: ${colors.bg};
    }

    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
    }

`;

export const buttonReset = css`
    font: inherit;
    color: inherit;
    background: none;
    cursor: pointer;
    border: none;
    padding: 0;
    &:disabled {
        cursor: default;
    }
`;

export const linkReset = css`
    margin: 0;
    text-decoration: none;
    color: inherit;
`;

export const buttonText = css`
    color: ${colors.black};
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
`;

export const button = css`
    ${buttonReset};
    ${linkReset};

    align-items: center;
    background: ${colors.actionPurple};
    border: none;
    border-radius: 2px;
    color: ${colors.neutral};
    display: flex;
    font-size: 16px;
    font-weight: 500;
    justify-content: center;
    line-height: 1.2;
    line-height: 48px;
    padding: 0 17px;

    &:hover {
        background: ${colors.actionHover};
    }

    &:active {
        background: ${colors.actionPressed};
    }

    &:disabled {
        background: ${colors.actionPurple};
        opacity: 0.5;
    }
`;

export const buttonWithArrow = css`
    ${button};
    text-align: left;
    justify-content: flex-start;
    background: url(${arrowWhite}) no-repeat right 16px center, ${colors.actionPurple};

    &:hover {
        background: url(${arrowWhite}) no-repeat right 16px center, ${colors.actionHover};
    }

    &:active {
        background: url(${arrowWhite}) no-repeat right 16px center, ${colors.actionPressed};
    }
`;

export const buttonSecondary = css`
    ${buttonReset};
    ${linkReset};

    align-items: center;
    background: #fff;
    border: none;
    box-shadow: 0px 0px 17px rgba(19, 5, 17, 0.1);
    border-radius: 2px;
    color: ${colors.actionPurple};
    display: flex;
    font-size: 16px;
    font-weight: 500;
    justify-content: center;
    line-height: 1.2;
    line-height: 48px;
    padding: 0 17px;

    &:hover {
        color: ${colors.actionHover};
    }

    &:active {
        color: ${colors.actionPressed};
    }
`;
