import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useStore } from '../StoreProvider';
import { cols, h2, button, h1 } from '../styles';

const Wrapper = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 99;
    box-shadow: 0px 10px 17px rgba(65, 13, 57, 0.1);

    color: #fff;
    background: #cc0000;
    .inner {
        ${cols(8)};
        margin: 0 auto;
        padding: 32px;
    }

    p {
        ${h2};
    }

    h2 {
        ${h1};
        margin: 0 0 8px;
    }

    button {
        float: right;
        ${button}
    }
`;

const Errors = observer(() => {
    let rootStore = useStore();
    let errors = rootStore.errors;

    if (errors.length === 0) {
        return null;
    }

    return (
        <Wrapper>
            <div className="inner">
                {errors.map((error, index) => (
                    <React.Fragment key={index}>
                        <h2>Error</h2>
                        <p>{error.toString()}</p>
                    </React.Fragment>
                ))}
                <button onClick={() => rootStore.dismissErrors()}>Dismiss</button>
                <div style={{ clear: 'both' }} />
            </div>
        </Wrapper>
    );
});

export default Errors;
