import React from 'react';
import styled from 'styled-components';

import { colors, buttonReset } from '../styles';

const SliderStyle = styled.button<{ enabled: boolean }>`
    ${buttonReset};
    display: block;
    transition: background 0.1s ease-out;
    background: ${colors.formGrey};
    border-radius: 24px;
    width: 48px;
    height: 24px;
    box-shadow: inset 0px 0px 10px rgba(19, 5, 17, 0.1);

    ${props =>
        props.enabled &&
        `
        background: ${colors.actionPurple};
    `};

    .ball {
        height: 24px;
        width: 24px;
        border-radius: 24px;
        background: ${colors.neutral};
        transition: background 0.1s ease-out, transform 0.1s ease-out, border-color 0.1s ease-out;
        border: 1px solid ${colors.formGrey};
        ${props =>
            props.enabled &&
            `
            background: ${colors.bg};
            border-color: ${colors.actionPurple};
            transform: translate(24px);
        `};
    }
`;

interface SliderProps {
    enabled: boolean;
    onChange: (enabled: boolean) => void;
    className?: string;
}

const Slider: React.FC<SliderProps> = ({ enabled, onChange, className }) => (
    <SliderStyle
        className={className}
        onClick={() => {
            onChange(!enabled);
        }}
        enabled={enabled}
    >
        <div className="ball" />
    </SliderStyle>
);

export default Slider;
