import React from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player/lazy';

const Video: React.FC<{ url?: string }> = ({ url }) => {
    if (url && ReactPlayer.canPlay(url)) {
        return (
            <StyledVideoContainer>
                <ReactPlayer url={url} height={'500px'} />
            </StyledVideoContainer>
        );
    } else {
        return null;
    }
};

const StyledVideoContainer = styled.div`
    display: flex;
    margin: 0 auto;
    width: 100%;
    justify-content: center;
`;

export default Video;
