import React from 'react';
import styled from 'styled-components';

import inProgress from '../img/in-progress.svg';
import { colors } from '../styles';

export const ProgressTextStyle = styled.div`
    font-weight: 500;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 14px;

    @media (min-width: 800px) {
        font-size: 16px;
        line-height: 16px;
    }
`;

interface ProgressTextProps {
    className?: string;
    numberOfDoneTasks: number;
    numberOfTasks: number;
    numberOfNaTasks: number;
}

const Text = styled.span`
    min-width: 50px;
`;

const Dot = styled.div<{ background: string }>`
    border: 1px solid ${colors.unBlue};
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background: ${props => props.background};

    @media (min-width: 800px) {
        margin-left: 8px;
    }
`;

const ProgressText: React.FC<ProgressTextProps> = ({
    className,
    numberOfDoneTasks,
    numberOfTasks,
    numberOfNaTasks,
}) => {
    let background;
    let totalToBeDone = numberOfTasks - numberOfNaTasks;
    if (numberOfDoneTasks < totalToBeDone) {
        if (numberOfDoneTasks === 0) {
            background = 'none';
        } else {
            background = `url(${inProgress})`;
        }
    } else {
        background = colors.unBlue;
    }

    return (
        <ProgressTextStyle className={className}>
            <Text>
                {numberOfDoneTasks} / {totalToBeDone}
            </Text>
            <Dot background={background} />
        </ProgressTextStyle>
    );
};

export default ProgressText;
