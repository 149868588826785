import { flow, Instance, types } from 'mobx-state-tree';
import shedil from '../api/shedil';

export type IndustryWithStats = {
    id: string;
    industry: string;
    inactive: string;
    ongoing: string;
    visible: boolean;
    total: string;
};

const IndustryStats = types.model('Industry', {
    id: types.identifier,
    industryName: types.string,
    ongoing: types.string,
    inactive: types.string,
    visible: types.boolean,
    total: types.number,
});

export interface IIndustryStats extends Instance<typeof IndustryStats> {}

export const StatStore = types
    .model('StatStore', {
        access: false,
        stat: types.array(IndustryStats),
        isLoading: true,
    })
    .actions(self => ({
        clearStat() {
            self.access = false;
            self.stat.clear();
            self.isLoading = false;
        },
        loadStat: flow(function* loadStat(userId) {
            if (userId) {
                try {
                    self.isLoading = true;

                    let res = yield shedil.get('/v1/statistics');

                    if (res.data) {
                        self.access = true;
                        res.data.forEach((ind: IndustryWithStats) => {
                            self.stat.push({
                                id: ind.id,
                                industryName: ind.industry,
                                ongoing: ind.ongoing,
                                inactive: ind.inactive,
                                visible: ind.visible,
                                total: parseInt(ind.total),
                            });
                        });
                    } else {
                        self.access = false;
                        self.stat.clear();
                    }
                    self.isLoading = false;
                } catch (err: any) {
                    self.access = false;
                    self.stat.clear();
                }
            }
            self.isLoading = false;
        }),
    }));
