import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import * as firebaseui from 'firebaseui';
import { observer } from 'mobx-react-lite';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import { useStore } from '../../StoreProvider';
import styled from 'styled-components';

const LoginWrapper = styled.div`
    height: calc(100vh - 103px - 302px);
    display: flex;
    justify-content: center;
    align-items: center;
`;

const uiConfig: firebaseui.auth.Config = {
    signInFlow: 'popup',
    callbacks: {
        signInSuccessWithAuthResult: (authResult, redirectUrl) => {
            if(authResult.additionalUserInfo.isNewUser) {
                window.location.href = '/s/preferences?firstLogin=true';
            }
            return false;
        },
    },
    signInOptions: [
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    ],
};

interface LoginRequiredProps {
    children: React.ReactNode;
}

const LoginRequired: React.FC<LoginRequiredProps> = observer(({ children }) => {
    let store = useStore();

    if (store.authStore.currentUser === undefined) {
        return (
            <LoginWrapper>
                <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
            </LoginWrapper>
        );
    }

    return <div>{children}</div>;
});

export default LoginRequired;
