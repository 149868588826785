import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { space } from 'styled-system';
import BlockContent from '@sanity/block-content-to-react';

import { button, h1, cols, buttonSecondary, h2, p2 } from '../styles';
import mainImage from '../img/tanzania.jpg';
import modelLarge from '../img/model.svg';
import modelSmall from '../img/model_small.svg';
import stopIcon from '../img/stopIcon.svg';
import howBottom from '../img/howBottom.jpg';
import { useMedia } from '../lib/hooks';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import { observer } from 'mobx-react-lite';
import { useStore } from '../StoreProvider';
import {
    defaultLanguageString,
    I18nStr,
    useLocalizedBlockObject,
    useLocalizedString,
} from '../i18n/localization';
import Video from '../components/Video';

const Wrapper = styled.div`
    margin: 48px 0 200px;

    h1 {
        ${h1};
        ${cols(8)};
        margin: 0 auto 109px;
        padding: 0 16px;
        @media (min-width: 600px) {
            padding: 0 32px;
        }
        @media (min-width: 1200px) {
            padding: 0;
        }
    }

    & > p {
        ${cols(6)};
        margin: 70px auto;
        max-width: 710px;
        padding: 0 16px;
        @media (min-width: 600px) {
            padding: 0 32px;
        }
        @media (min-width: 1200px) {
            padding: 0;
        }
    }

    & > .steps {
        font-weight: 100;
    }

    .model {
        ${cols(7)};
        margin: 40px auto 150px;
        padding: 0 16px;
        @media (min-width: 600px) {
            padding: 0 32px;
        }
        @media (min-width: 1200px) {
            padding: 0 121px 0 0;
        }

        & > img {
            display: block;
            max-width: 100%;
        }

        .attribution {
            font-weight: 100;
            text-align: right;
        }
    }

    .fullWidthImage {
        ${cols(12)};
        display: block;
        margin: 0 auto;
    }

    & > .ctabox {
        display: flex;
        justify-content: center;
        align-items: center;
        & > .cta {
            ${button};
            margin: 0 0 64px;
        }
    }
`;
const StyledBlockContent = styled(BlockContent)`
    ${cols(6)};
    margin: 70px auto;
    max-width: 710px;
    padding: 0 16px;
    @media (min-width: 600px) {
        padding: 0 32px;
    }
    @media (min-width: 1200px) {
        padding: 0;
    }
`;

const BoxWithIllustration = styled.div<any>`
    ${cols(8)};
    display: flex;
    margin: 0 auto;
    padding: 0 16px;
    @media (min-width: 600px) {
        padding: 0 32px;
    }
    flex-direction: column;
    @media (min-width: 400px) {
        flex-direction: row;
    }
    @media (min-width: 1200px) {
        padding: 0;
    }
    ${space};

    & > .left {
        ${cols(1)};
    }
    & > .right {
        ${cols(7)};
    }

    strong {
        font-weight: 500;
    }
`;

const ModelLegend = styled.ul<{ showProcessText: boolean }>`
    ${p2};
    padding: 0;
    list-style-type: none;
    margin: 0 16px;
    @media (min-width: 600px) {
        display: ${({ showProcessText }) => (showProcessText ? '' : 'none')};
    }
`;

const WhiteBox = styled.div`
    background: #ffffff;
    box-shadow: 0px 0px 50px rgba(19, 5, 17, 0.1);
    padding: 86px 16px 64px;
    @media (min-width: 600px) {
        padding: 86px 32px 64px;
    }
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 1200px) {
        padding: 86px 0 64px;
    }

    & > p {
        ${h2};
        ${cols(8)};
        @media (min-width: 1200px) {
            padding-right: 242px;
        }
    }

    & > .cta {
        ${buttonSecondary};
        margin: 64px auto 0;
    }
`;

const Home = observer(() => {
    let rootStore = useStore();
    const commitment = rootStore.commitmentStore.commitment;
    const how = rootStore.howPageStore.howPage;
    let lang = rootStore.language.selectedLanguage;

    const model = useMedia(['(min-width: 600px)'], [modelLarge], modelSmall);

    return (
        <Wrapper>
            <ScrollToTopOnMount />
            <h1>
                <I18nStr>{how.title}</I18nStr>
            </h1>
            <StyledBlockContent blocks={useLocalizedBlockObject(how.text)} />
            <Video url={how.video} />
            <p>
                <I18nStr>{commitment.figure}</I18nStr>
            </p>
            <div className="model">
                <img src={model} alt={useLocalizedString(commitment.figure)} />
                <ModelLegend showProcessText={lang !== defaultLanguageString}>
                    <li>
                        1. <I18nStr>{commitment.step1}</I18nStr>
                    </li>
                    <li>
                        2. <I18nStr>{commitment.step2}</I18nStr>
                    </li>
                    <li>
                        3. <I18nStr>{commitment.step3}</I18nStr>
                    </li>
                    <li>
                        4. <I18nStr>{commitment.step4}</I18nStr>
                    </li>
                    <li>
                        &rarr; <I18nStr>{commitment.step45}</I18nStr>
                    </li>
                    <li>
                        5. <I18nStr>{commitment.step5}</I18nStr>
                    </li>
                </ModelLegend>
                <p className="attribution">
                    From "OECD Due Diligence Guidance for Responsible Business Conduct", OECD 2018
                </p>
            </div>
            <img className="fullWidthImage" alt="a woman carrying water" src={mainImage} />
            <BoxWithIllustration mt={96} mb={176}>
                <div className="left">
                    <img src={stopIcon} alt="" />
                </div>
                <div className="right">
                    <StyledBlockContent blocks={useLocalizedBlockObject(how.stopText)} />
                </div>
            </BoxWithIllustration>
            <WhiteBox>
                <p>
                    <I18nStr>{how.ctaText}</I18nStr>
                </p>
            </WhiteBox>
            <div className="ctabox">
                <Link className="cta" to="/s/list">
                    <I18nStr>{how.ctaButton}</I18nStr>
                </Link>
            </div>
            <img className="fullWidthImage" alt="a fabric" src={howBottom} />
        </Wrapper>
    );
});

export default Home;
