import { types, flow, Instance } from 'mobx-state-tree';
import { SanityImageSource } from '@sanity/image-url/lib/types/types';

import sanity from '../api/sanity';
import {
    SanityLocaleBlockContent,
    SanityLocaleString,
    SanityLocaleText,
} from '../i18n/localization';

// TODO: Make this a better type
export type TextBlock = any;
export type BlockContent = TextBlock[];

export const Task = types.model('Task', {
    _key: types.identifier,
    ltitle: types.frozen<SanityLocaleText>(),
    description: types.maybe(types.string),
    ltext: types.frozen<SanityLocaleBlockContent>(),
    image: types.frozen<SanityImageSource>(),
});

export const Relation = types.model('Relation', {
    _key: types.identifier,
    lname: types.frozen<SanityLocaleString>(),
    description: types.maybe(types.string),
    ltext: types.frozen<SanityLocaleBlockContent>(),
    assess: types.array(types.reference(Task)),
    integrateAndAct: types.array(types.reference(Task)),
    track: types.array(types.reference(Task)),
    communicate: types.array(types.reference(Task)),
});

export const Industry = types.model('Industry', {
    _id: types.identifier,
    lname: types.frozen<SanityLocaleString>(),
    ldescription: types.frozen<SanityLocaleText>(),
    ltext: types.frozen<SanityLocaleBlockContent>(),
    relations: types.array(types.reference(Relation)),
    visible: types.boolean,
});

export interface ITask extends Instance<typeof Task> {}
export interface IIndustry extends Instance<typeof Industry> {}
export interface IRelation extends Instance<typeof Relation> {}

export const TaskStore = types
    .model('TaskStore', {
        isLoading: true,
        industries: types.map(Industry),
        relations: types.map(Relation),
        tasks: types.map(Task),
    })
    .actions(self => ({
        loadData: flow(function* loadData() {
            try {
                let industries = yield sanity.fetch(`*[_type=="industry"]`) as Promise<any>;

                industries.forEach((industry: any) => {
                    industry.relations = industry.relations || [];
                    self.industries.put({
                        _id: industry._id,
                        lname: industry.lname,
                        ldescription: industry.ldescription,
                        ltext: industry.ltext,
                        relations: industry.relations.map((r: any) => r._key),
                        visible: industry.visible,
                    });

                    industry.relations.forEach((r: any) => {
                        r.assess = r.assess || [];
                        r.integrateAndAct = r.integrateAndAct || [];
                        r.track = r.track || [];
                        r.communicate = r.communicate || [];

                        self.relations.put({
                            _key: r._key,
                            lname: r.lname,
                            description: r.description,
                            ltext: r.ltext,
                            assess: r.assess.map((t: any) => t._key),
                            integrateAndAct: r.integrateAndAct.map((t: any) => t._key),
                            track: r.track.map((t: any) => t._key),
                            communicate: r.communicate.map((t: any) => t._key),
                        });

                        r.assess
                            .concat(r.integrateAndAct)
                            .concat(r.track)
                            .concat(r.communicate)
                            .forEach((t: any) => {
                                self.tasks.put({
                                    _key: t._key,
                                    ltitle: t.ltitle,
                                    description: t.description,
                                    ltext: t.ltext,
                                    image: t.image,
                                });
                            });
                    });
                });

                self.isLoading = false;
            } catch (err) {
                console.error('Failed to load choice tree data', err);
            }
        }),
    }));
