import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { IInvestigation } from '../stores/InvestigationStore';
import { IRelation } from '../stores/TaskStore';
import ProgressBar from './ProgressBar';
import { h3 } from '../styles';
import { useStore } from '../StoreProvider';
import { useLocalizedString } from '../i18n/localization';

const Wrapper = styled.section`
    background: #fff;
    padding: 16px;
    width: 100%;

    @media (min-width: 500px) {
        min-width: 380px;
    }

    h1 {
        ${h3};
        font-size: 14px;
        margin: 0;
        font-weight: 500;

        @media (min-width: 800px) {
            ${h3};
        }
    }

    .progress {
        margin: 16px 0 0;
    }
`;

const OverallProgress: React.FC<{
    investigation: IInvestigation;
    relations: IRelation[];
    title: string;
    className?: string;
}> = observer(({ investigation, relations, title, className }) => {
    let config = useStore().configStore.config;
    let taskStatus = investigation.taskStatus;
    let relationStatus = investigation.relationStatus;
    let data = relations
        .map(relation => {
            let currentRelationStatus = relationStatus.get(relation._key);
            if (currentRelationStatus !== undefined && currentRelationStatus.notApplicable) {
                return { numberOfTasks: 0, numberOfDoneTasks: 0, numberOfNaTasks: 0 };
            }
            let tasks = relation.assess
                .concat(relation.integrateAndAct.slice())
                .concat(relation.track.slice())
                .concat(relation.communicate.slice());

            let numberOfTasks = tasks.length;
            let numberOfDoneTasks = tasks.filter(
                task => taskStatus.get(task._key) && taskStatus.get(task._key)!.done
            ).length;
            let numberOfNaTasks = tasks.filter(
                task => taskStatus.get(task._key) && taskStatus.get(task._key)!.notApplicable
            ).length;

            return { numberOfTasks, numberOfDoneTasks, numberOfNaTasks };
        })
        .reduce(
            (a, b) => ({
                numberOfTasks: a.numberOfTasks + b.numberOfTasks,
                numberOfDoneTasks: a.numberOfDoneTasks + b.numberOfDoneTasks,
                numberOfNaTasks: a.numberOfNaTasks + b.numberOfNaTasks,
            }),
            { numberOfTasks: 0, numberOfDoneTasks: 0, numberOfNaTasks: 0 }
        );

    if (data.numberOfTasks === 0) {
        return (
            <Wrapper>
                <h1>{title}</h1>
                <div>{useLocalizedString(config.noTaskApplicable)}</div>
            </Wrapper>
        );
    }

    return (
        <Wrapper className={className}>
            <h1>{title}</h1>
            <ProgressBar
                className="progress"
                value={data.numberOfDoneTasks}
                max={data.numberOfTasks - data.numberOfNaTasks}
                caption={useLocalizedString(config.done).toLowerCase()}
            />
            <ProgressBar
                className="progress"
                value={data.numberOfNaTasks}
                max={data.numberOfTasks}
                caption={useLocalizedString(config.dismissedAsNotApplicable)}
                color="#DEA5A5"
            />
        </Wrapper>
    );
});

export const OverallProgressTotal: React.FC<{
    investigation: IInvestigation;
    className?: string;
}> = observer(({ investigation, className }) => {
    let config = useStore().configStore.config;

    return (
        <OverallProgress
            className={className}
            relations={investigation.industry.relations}
            investigation={investigation}
            title={useLocalizedString(config.overallProgress)}
        />
    );
});

export const OverallProgressRelation: React.FC<{
    investigation: IInvestigation;
    relation: IRelation;
    className?: string;
}> = observer(({ investigation, relation, className }) => {
    let config = useStore().configStore.config;

    return (
        <StyledOverallProgress
            className={className}
            relations={[relation]}
            investigation={investigation}
            title={useLocalizedString(config.businessRelationProgress)}
        />
    );
});

const StyledOverallProgress = styled(OverallProgress)`
    margin: 0 8px;
    width: calc(100% - 16px);

    @media (min-width: 1000px) {
        max-width: 380px;
    }
`;
