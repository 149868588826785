import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { Link, useParams } from 'react-router-dom';

import { h3, linkReset, colors, cols, h2, p2 } from '../styles';
import { useStore } from '../StoreProvider';
import { IRelation } from '../stores/TaskStore';
import { IInvestigation } from '../stores/InvestigationStore';

import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import Slider from '../components/Slider';
import PageWrapper from '../components/PageWrapper';
import ProgressText from '../components/ProgressText';
import { OverallProgressTotal } from '../components/OverallProgress';
import MoreInfo from '../components/MoreInfo';
import { InvestigationHeader } from '../components/InvestitigationHeader';
import { ExpandableRichText } from '../components/ExpandableRichText';
import { I18nStr, useLocalizedBlockObject, useLocalizedString } from '../i18n/localization';

interface InvestigationIndexProps {
    investigationId: string;
}

const RelationProgress: React.FC<{
    relation: IRelation;
    investigation: IInvestigation;
    className?: string;
}> = observer(({ relation, investigation, className }) => {
    let taskStatus = investigation.taskStatus;

    let tasks = relation.assess
        .concat(relation.integrateAndAct.slice())
        .concat(relation.track.slice())
        .concat(relation.communicate.slice());

    let numberOfTasks = tasks.length;
    let numberOfDoneTasks = tasks.filter(
        task => taskStatus.get(task._key) && taskStatus.get(task._key)!.done
    ).length;
    let numberOfNaTasks = tasks.filter(
        task => taskStatus.get(task._key) && taskStatus.get(task._key)!.notApplicable
    ).length;

    return (
        <ProgressText
            className={className}
            numberOfDoneTasks={numberOfDoneTasks}
            numberOfTasks={numberOfTasks}
            numberOfNaTasks={numberOfNaTasks}
        />
    );
});

const InvestigationIndex: React.FC<{}> = observer(() => {
    let { investigationId } = useParams<InvestigationIndexProps>();
    let rootStore = useStore();
    let config = rootStore.configStore.config;
    let investigationStore = rootStore.investigationStore;
    let text = rootStore.generalTextsStore.generalTexts;

    let maybeInvestigation = investigationStore.investigations.get(investigationId);

    if (maybeInvestigation === undefined) {
        return <div>SheDil not found</div>;
    }
    let investigation = maybeInvestigation;

    let industry = investigation.industry;

    return (
        <div>
            <ScrollToTopOnMount />
            <PageWrapper>
                <InvestigationHeader
                    investigation={investigation}
                    backlink="/s/list"
                    backlinkText={useLocalizedString(text.dashboard)}
                />
                <StyledInfoSectionDiv>
                    <StyledHeaderDiv>
                        <StyledHeader>
                            <I18nStr>{industry.lname}</I18nStr>
                        </StyledHeader>
                        <ExpandableRichText content={useLocalizedBlockObject(industry.ltext)} />
                    </StyledHeaderDiv>
                    <StyledOverallProgressTotal investigation={investigation} />
                </StyledInfoSectionDiv>
                <Relations>
                    <StyledRelationsMoreInforDiv>
                        <div className="header main_header">
                            <I18nStr>{config.relations}</I18nStr>
                            <MoreInfo
                                className="more_info"
                                content={useLocalizedString(config.relationshipInfo)}
                            />
                        </div>
                        <div>
                            <p>
                                <I18nStr>{config.investigationRelationshipsIntro}</I18nStr>
                            </p>
                        </div>
                    </StyledRelationsMoreInforDiv>
                    <div className="header extra_header">
                        <I18nStr>{config.relationsPogressHeader}</I18nStr>
                    </div>
                    <div className="header extra_header">
                        <I18nStr>{config.relationsApplicableHeader}</I18nStr>
                    </div>
                    {industry.relations.map(relation => {
                        let status = investigation.relationStatus.get(relation._key);
                        let isApplicable = !((status && status.notApplicable) || false);

                        return (
                            <React.Fragment key={relation._key}>
                                {/* As in RelationLink disables it when not applicable */}
                                <RelationLink
                                    as={isApplicable ? Link : 'a'}
                                    to={`/s/she/${investigationId}/r/${relation._key}`}
                                    className={!isApplicable ? 'disabled' : ''}
                                >
                                    <I18nStr>{relation.lname}</I18nStr>
                                </RelationLink>
                                {isApplicable ? (
                                    <StyledRelationProgress
                                        relation={relation}
                                        investigation={investigation}
                                    />
                                ) : (
                                    <div />
                                )}
                                <StyledApplicableWrapperDiv>
                                    <StyledSpan>APPL.</StyledSpan>
                                    <StyledSlider
                                        enabled={isApplicable}
                                        onChange={enabled => {
                                            investigation.setRelationNotApplicable(
                                                relation._key,
                                                !enabled
                                            );
                                        }}
                                    />
                                </StyledApplicableWrapperDiv>
                            </React.Fragment>
                        );
                    })}
                </Relations>
            </PageWrapper>
        </div>
    );
});

export default InvestigationIndex;

const StyledRelationsMoreInforDiv = styled.div`
    grid-column: 1 / span 2;

    @media (min-width: 650px) {
        grid-column: 1 / span 1;
    }
`;

const StyledRelationProgress = styled(RelationProgress)`
    margin-bottom: 16px;
    margin-top: 8px;
    margin-left: 16px;

    @media (min-width: 650px) {
        margin: 0;
    }
`;

const StyledApplicableWrapperDiv = styled.div`
    justify-self: end;

    margin-bottom: 16px;
    margin-top: 8px;
    margin-right: 24px;

    display: flex;

    @media (min-width: 650px) {
        margin: 0;
    }
`;

const StyledSpan = styled.span`
    ${p2};
    @media (min-width: 650px) {
        display: none;
    }
`;

const StyledSlider = styled(Slider)`
    margin-left: 16px;

    @media (min-width: 650px) {
        margin: 0;
    }
`;

const StyledInfoSectionDiv = styled.div`
    display: flex;
    flex-direction: column;
    margin: 32px 0 0;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 40px;

    @media (min-width: 1000px) {
        flex-direction: row;
    }
`;

const StyledHeader = styled.h1`
    ${h2};
    margin: 0 0 8px;
`;

const StyledHeaderDiv = styled.div`
    max-width: 800px;
    margin-bottom: 16px;
    @media (min-width: 1000px) {
        max-width: 590px;
    }
`;

// TODO: Some type-bug makes this any required.
const RelationLink: any = styled(Link)`
    ${linkReset};
    display: block;
    background: ${colors.formGrey};
    padding: 0 16px;
    line-height: 48px;
    max-width: 590px;
    width: 100%;
    font-weight: 500;
    border-radius: 2px;

    &:hover {
        background: ${colors.hoverGrey};
    }

    &.disabled {
        opacity: 0.5;
    }
`;

const Relations = styled.div`
    ${cols(8)};
    display: grid;
    align-items: center;
    margin: 16px 0;
    grid-template-columns: 1fr 1fr;
    @media (min-width: 650px) {
        grid-gap: 16px;
        grid-template-columns: minmax(340px, 600px) auto min-content;
    }

    .extra_header {
        display: none;

        @media (min-width: 650px) {
            display: block;
        }
    }

    .main_header {
        display: flex;
        align-items: center;

        .more_info {
            margin-left: 30px;
        }
    }

    ${RelationLink} {
        grid-column: 1 / span 2;
        @media (min-width: 650px) {
            grid-column: 1 / span 1;
        }
    }

    & > .header {
        ${h3};
        padding-top: 48px;
    }
`;

const StyledOverallProgressTotal = styled(OverallProgressTotal)`
    margin: 0 8px;
    width: calc(100% - 16px);

    @media (min-width: 1000px) {
        max-width: 380px;
    }
`;
