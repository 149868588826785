import { types, Instance } from 'mobx-state-tree';
import { autorun } from 'mobx';
import * as Sentry from '@sentry/browser';

import { AuthStore } from './AuthStore';
import { ChoiceTreeStore } from './ChoiceTreeStore';
import { TaskStore } from './TaskStore';
import { InvestigationStore } from './InvestigationStore';
import { ConfigStore } from './ConfigStore';
import { FooterStore } from './FooterStore';
import { defaultLanguage, SelectedLanguage } from './LanguageStore';
import { LandingPageStore } from './LandingPageStore';
import { defaultGeneralTexts, GeneralTextsStore } from './GeneraltextsStore';
import { CommitmentStore } from './CommitmentStore';
import { StatStore } from './StatStore';
import { HowPageStore } from './HowStore';

export const RootStore = types
    .model('RootStore', {
        errors: types.optional(types.frozen<Error[]>(), []),
        authStore: types.optional(AuthStore, {}),
        statStore: types.optional(StatStore, {}),
        choiceTreeStore: types.optional(ChoiceTreeStore, {}),
        taskStore: types.optional(TaskStore, {}),
        configStore: types.optional(ConfigStore, {}),
        investigationStore: types.optional(InvestigationStore, {}),
        footerStore: types.optional(FooterStore, {}),
        commitmentStore: types.optional(CommitmentStore, {}),
        language: types.optional(SelectedLanguage, defaultLanguage),
        landingPageStore: types.optional(LandingPageStore, {}),
        generalTextsStore: types.optional(GeneralTextsStore, defaultGeneralTexts),
        howPageStore: types.optional(HowPageStore, {}),
    })
    .views(self => ({
        get isLoading() {
            return (
                self.authStore.isLoading ||
                self.taskStore.isLoading ||
                self.investigationStore.isLoading ||
                self.statStore.isLoading ||
                self.configStore.config === undefined ||
                self.footerStore.footer === undefined ||
                self.commitmentStore.commitment === undefined ||
                self.landingPageStore.landingPage === undefined ||
                self.generalTextsStore.generalTexts === undefined ||
                self.howPageStore.howPage === undefined
            );
        },
        get isPending() {
            return self.investigationStore.isPending;
        },
    }))
    .actions(self => ({
        setError(err: Error, context: string) {
            console.error(context, err);
            Sentry.withScope(scope => {
                scope.setExtra('redux_error_happened', 'true');
                scope.setExtra('moreContext', context);
                Sentry.captureException(err);
            });

            self.errors = [err];
        },
        dismissErrors() {
            self.errors = [];
        },
        loadData() {
            self.authStore.startAuthChecker();
            self.choiceTreeStore.loadData();
            self.taskStore.loadData();
            self.configStore.loadData();
            self.footerStore.loadData();
            self.commitmentStore.loadData();
            self.landingPageStore.loadData();
            self.generalTextsStore.loadData();
            self.howPageStore.loadData();

            autorun(() => {
                // This will re-run if either of its dependencies (i.e. self.authStore.currentUser)
                // changes
                if (self.authStore.currentUser) {
                    self.investigationStore.loadData(self.authStore.currentUser.uid);
                    self.statStore.loadStat(self.authStore.currentUser.uid);
                } else {
                    self.investigationStore.clearData();
                    self.statStore.clearStat();
                }
            });
        },
    }));

export interface IRootStore extends Instance<typeof RootStore> {}
