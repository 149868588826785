import Client from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';
import { Domain } from '../types/sanity';
import { SanityImageSource } from '@sanity/image-url/lib/types/types';

export const projectId = 'kfdu7885';
export const dataset = process.env.NODE_ENV === 'production' ? 'production' : 'testing';

const sanity = Client({
    projectId,
    dataset,
    useCdn: true,
    apiVersion: '2022-01-20',
});

export default sanity;

export function getDomains() {
    return sanity.fetch<Domain[]>(`*[_type=="domain"]`);
}

const urlBuilderFactory = imageUrlBuilder(sanity);

export function urlFor(image: SanityImageSource) {
    return urlBuilderFactory.image(image);
}
