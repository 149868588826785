import { types, flow, getParentOfType } from 'mobx-state-tree';

import sanity from '../api/sanity';
import { RootStore } from './RootStore';
import { SanityLocaleString } from '../i18n/localization';

export interface GeneralTexts {
    scrollToLearn?: SanityLocaleString | string;
    about: SanityLocaleString | string;
    access: SanityLocaleString | string;
    dashboard: SanityLocaleString | string;
    logOut: SanityLocaleString | string;
    logIn: SanityLocaleString | string;
    done: SanityLocaleString | string;
    cancel: SanityLocaleString | string;
    renameSheDil: SanityLocaleString | string;
    deleteSheDil: SanityLocaleString | string;
    deleteConfirmationHeader: SanityLocaleString | string;
    deleteConfirmation: SanityLocaleString | string;
    setSheDilAsActive: SanityLocaleString | string;
    setSheDilAsInactive: SanityLocaleString | string;
    download: SanityLocaleString | string;
    back: SanityLocaleString | string;
    save: SanityLocaleString | string;
    close: SanityLocaleString | string;
    readMore: SanityLocaleString | string;
    readLess: SanityLocaleString | string;
    preferences: SanityLocaleString | string;
    allowEmailContact: SanityLocaleString | string;
    skip: SanityLocaleString | string;
}

export let defaultGeneralTexts: GeneralTexts = {
    scrollToLearn: 'Scroll to learn',
    about: 'About',
    access: 'Access SheDil',
    dashboard: 'Dashboard',
    logOut: 'Log Out',
    logIn: 'Log in',
    done: 'Done',
    cancel: 'Cancel',
    renameSheDil: 'Rename',
    deleteSheDil: 'Delete',
    setSheDilAsActive: 'Set as active',
    setSheDilAsInactive: 'Set as inactive',
    download: 'Download report as excel',
    back: 'Back',
    save: 'Save',
    close: 'Close',
    readMore: 'Read more',
    readLess: 'Read less',
    deleteConfirmationHeader: 'Please Confirm!',
    deleteConfirmation: 'Are you sure you want to delete the SheDil. This is permanent.',
    skip: 'Skip',
    preferences: 'Preferences',
    allowEmailContact: 'Allow SheDil to contact me via email',
};

export const GeneralTextsStore = types
    .model('GeneralTextsStore', {
        generalTexts: types.optional(types.frozen<GeneralTexts>(), defaultGeneralTexts),
    })
    .actions(self => ({
        loadData: flow(function* loadData() {
            try {
                let generaltexts = yield sanity.fetch(
                    `*[_type=="generalTexts"][0]`
                ) as Promise<GeneralTexts>;

                self.generalTexts = {
                    ...defaultGeneralTexts,
                    ...generaltexts,
                };
            } catch (err: any) {
                getParentOfType(self, RootStore).setError(err, 'GeneralTextsStoreLoadData');
            }
        }),
    }));
