import { types, flow, getParentOfType } from 'mobx-state-tree';

import { db } from '../api/firebase';
import { RootStore } from './RootStore';

// Returned from sortedTree
export interface TreeNode {
    id: string;
    title: string;
    description: string;
    type: string;
    children: TreeNode[];
}

export const Node = types.model('Node', {
    id: types.identifier,
    title: types.string,
    description: types.string,
    // TODO: Use a form of enum here!
    type: types.string,
    children: types.array(types.string),
});

export const ChoiceTreeStore = types
    .model('ChoiseTreeStore', {
        isLoading: true,
        nodes: types.map(Node),
    })
    .views(self => ({
        get sortedTree(): TreeNode {
            const convertToTreeNode = (ref: string): TreeNode => {
                let node = self.nodes.get(ref);
                if (node === undefined) {
                    console.error(`Could not find node with reference ${ref}`);

                    return {
                        id: ref,
                        title: 'ERROR: UNKNOWN NODE',
                        description: `Node with id ${ref} does not exist`,
                        type: 'task',
                        children: [],
                    };
                }
                return {
                    id: node.id,
                    title: node.title,
                    description: node.description,
                    type: node.type,
                    children: node.children.map(childRef => convertToTreeNode(childRef)),
                };
            };

            return convertToTreeNode('root');
        },
    }))
    .actions(self => ({
        loadData: flow(function* loadData() {
            try {
                let querySnapshot = yield db.collection('nodes').get();
                querySnapshot.forEach((doc: any) => {
                    let data = doc.data();
                    self.nodes.put({
                        id: doc.id,
                        title: data.title,
                        description: data.description,
                        type: data.type,
                        children: data.children || [],
                    });
                });
                self.isLoading = false;
            } catch (err: any) {
                getParentOfType(self, RootStore).setError(err, 'ChoiceTreeStoreLoadData');
            }
        }),
    }));
