import React from 'react';
import styled from 'styled-components';

import { colors, buttonReset } from '../styles';
import checkmark from '../img/checkmark.svg';

const CheckboxStyle = styled.button<{ checked: boolean }>`
    ${buttonReset};
    display: block;
    background: #c4c4c4;
    border-radius: 4px;
    width: 24px;
    height: 24px;
    margin: 0;

    ${props =>
        props.checked &&
        `
        background: url(${checkmark}) no-repeat center center, ${colors.orangeLight};
    `};

    &:disabled {
        opacity: 0.5;
    }
`;

interface CheckboxProps {
    checked: boolean;
    disabled: boolean;
    onChange: (checked: boolean) => void;
}

const Checkbox: React.FC<CheckboxProps> = ({ checked, onChange, disabled }) => (
    <CheckboxStyle
        disabled={disabled}
        onClick={() => {
            if (!disabled) {
                onChange(!checked);
            }
        }}
        checked={checked}
    />
);

export default Checkbox;
