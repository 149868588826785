import React, { useState } from 'react';
import styled from 'styled-components';
import { values } from 'mobx';
import { Link, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import countries from '../data/countries.json';
import { useStore } from '../StoreProvider';
import { IIndustry } from '../stores/TaskStore.js';
import {
    buttonWithArrow,
    h2,
    colors,
    p2,
    buttonReset,
    button,
    inputStyle,
    linkReset,
} from '../styles';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import PageWrapper from '../components/PageWrapper';
import Spinner from '../components/Spinner';
import Commitment from '../components/Commitment';
import { I18nStr, useLocalizedString } from '../i18n/localization';

const Industries = styled.div`
    margin: 25px 0;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;

    .industry {
        border-radius: 2px;
        ${buttonReset};
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0;
        text-align: left;
        width: 100%;
        background: #ffffff;
        margin-right: 16px;
        margin-bottom: 16px;
        transition: background 0.1s ease-out, box-shadow 0.1s ease-out, transform 0.1s ease-out;

        @media (min-width: 600px) {
            max-width: 240px;
        }

        &:hover {
            box-shadow: none;
            box-shadow: 0px 4px 20px rgba(19, 5, 17, 0.1);
        }

        &:active {
            background: ${colors.hoverGrey};
            transform: translateY(8px);
        }

        h2 {
            ${h2};
            margin: 20px 0 16px;
        }

        .description {
            ${p2};
            margin: 0 0 auto;
            padding: 0 16px 16px;
        }

        .button {
            ${buttonWithArrow};
            width: 100%;
            border-radius: 0 0 2px 2px;
        }
    }
`;

const SelectIndustryAndCreate: React.FC<{ name: string }> = observer(({ name }) => {
    let [createdId, setCreatedId] = useState<string | undefined>();
    let [loading, setLoading] = useState(false);

    let rootStore = useStore();
    let taskStore = rootStore.taskStore;
    let investigationStore = rootStore.investigationStore;
    let config = rootStore.configStore.config;

    const createInvestigation = (props: { industry: string; name: string }) => {
        setLoading(true);
        investigationStore.createInvestigation(props).then(investigationId => {
            setCreatedId(investigationId as string);
            setLoading(false);
        });
    };

    if (loading) {
        return (
            <div>
                <Spinner />
            </div>
        );
    }

    if (createdId !== undefined) {
        return <Redirect to={`/s/she/${createdId}`} />;
    }

    return (
        <div>
            <div style={{ height: 38 }} />
            <p>
                <I18nStr>{config.createShedilChooseSector}</I18nStr>
            </p>
            <Industries>
                {values(taskStore.industries)
                    .filter(ind => ind.visible !== false)
                    .map((industry: IIndustry) => (
                        <button
                            onClick={() =>
                                createInvestigation({
                                    industry: industry._id,
                                    name,
                                })
                            }
                            key={industry._id}
                            className="industry"
                        >
                            <div className="description">
                                <h2>
                                    <I18nStr>{industry.lname}</I18nStr>
                                </h2>
                                <p>
                                    <I18nStr>{industry.ldescription}</I18nStr>
                                </p>
                                <p>{industry.visible}</p>
                            </div>
                            <div className="button">
                                <I18nStr>{config.createShedilChooseButton}</I18nStr>{' '}
                                {useLocalizedString(industry.lname).split(' ')[0].toLowerCase()}
                            </div>
                        </button>
                    ))}
            </Industries>
        </div>
    );
});

const ChooseNameAndCreateInvestigation = observer(() => {
    let [name, setName] = useState('');
    let [nameDone, setNameDone] = useState(false);
    let rootStore = useStore();
    let config = rootStore.configStore.config;
    let texts = rootStore.generalTextsStore.generalTexts;

    let countriesNames = Object.keys(countries);
    countriesNames.sort();

    return (
        <div>
            <ScrollToTopOnMount />
            <PageWrapper>
                <HeaderWrapper>
                    <Header>
                        <I18nStr>{config.createShedilHeader}</I18nStr>
                    </Header>
                    <CancelLink to="/s/list">
                        <I18nStr>{texts.cancel}</I18nStr>
                    </CancelLink>
                </HeaderWrapper>

                <StatusDotWrapper>
                    <StatusDot enabled={true} />
                    <StatusDot enabled={nameDone} />
                </StatusDotWrapper>

                <InputWrapper>
                    <label htmlFor="name">
                        <I18nStr>{config.createShedilLabel}</I18nStr>
                    </label>
                    <InnerInputWrapper>
                        <input
                            id="name"
                            value={name}
                            onChange={ev => setName(ev.target.value)}
                            placeholder={useLocalizedString(config.createShedilPlaceholder)}
                        />
                        <button
                            disabled={nameDone}
                            onClick={() => {
                                setNameDone(true);
                            }}
                        >
                            <I18nStr>{texts.done}</I18nStr>
                        </button>
                    </InnerInputWrapper>
                </InputWrapper>
                <StyledNameDiv nameDone={nameDone}>
                    <SelectIndustryAndCreate name={name} />
                </StyledNameDiv>
            </PageWrapper>
        </div>
    );
});

const StyledNameDiv = styled.div<{ nameDone: boolean }>`
    ${({ nameDone }) => !nameDone && `opacity: 0.3; pointer-events: none;`}
`;

const CreateInvestigation = () => {
    let [commitmentOk, setCommitmentOk] = useState(false);

    if (commitmentOk) {
        return <ChooseNameAndCreateInvestigation />;
    }

    return <Commitment onCommit={() => setCommitmentOk(true)} />;
};

export default CreateInvestigation;

const HeaderWrapper = styled.div`
    margin-top: 70px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    max-width: 468px;
    margin-bottom: 24px;
    @media (min-width: 500px) {
        margin-bottom: 48px;
    }
`;

const Header = styled.h1`
    ${h2};
    margin: 0;
`;

const CancelLink = styled(Link)`
    ${linkReset};
    color: ${colors.actionPurple};
`;

const StatusDotWrapper = styled.div`
    display: flex;
    margin-bottom: 14px;
`;

const StatusDot = styled.div<{ enabled: boolean }>`
    width: 8px;
    height: 8px;
    background: ${props => (props.enabled ? colors.unBlue : colors.formGrey)};
    border-radius: 100%;
    margin-right: 4px;
`;

const InputWrapper = styled.div`
    input {
        display: block;
        width: 100%;
        max-width: 469px;
        ${inputStyle};
    }

    label {
        display: block;
        ${p2};
        margin-bottom: 10px;
    }

    button {
        ${button};
        margin-top: 16px;
        width: 100px;
        @media (min-width: 600px) {
            margin-left: 16px;
            margin-top: 0;
        }
    }
`;

const InnerInputWrapper = styled.div`
    display: flex;
    flex-direction: column;

    @media (min-width: 600px) {
        flex-direction: row;
    }
`;
