import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { animated, useSpring, useTrail, config, useChain, useSpringRef } from '@react-spring/web';

import { useCountUp } from 'react-countup';
import * as BlockContent from '@sanity/block-content-to-react';

import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import { button, linkReset, buttonReset, cols, colors, p2 } from '../styles';
import purpleArrow from '../img/purple-arrow.svg';
import arrowBig from '../img/arrowBig.svg';
import moreinfo from '../img/moreinfo.svg';
import goal5 from '../img/goal5.svg';
import goal8 from '../img/goal8.svg';
import { observer } from 'mobx-react-lite';
import { useStore } from '../StoreProvider';
import { useOnScreen } from '../lib/hooks';
import * as sanity from '../api/sanity';
import {
    I18nStr,
    SanityLocaleString,
    SanityLocaleUrl,
    useLocalizedBlockObject,
    useLocalizedString,
} from '../i18n/localization';

const HeroBackground = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 800px;

    background: linear-gradient(
        174.5deg,
        #ececec 12.32%,
        rgba(238, 220, 193, 0.4) 33.81%,
        rgba(248, 149, 0, 0) 81.91%
    );
`;

const Hero = styled.div<{ imageUrl: string }>`
    width: 100%;
    max-width: 326px;
    margin: 200px auto 400px;
    display: flex;
    flex-direction: column;
    position: relative;

    padding: 0 32px 0 16px;
    height: 384px;
    padding-top: 100px;

    @media (min-width: 600px) {
        margin: 350px auto 400px;
        padding-top: 0;
        ${cols(8)};
    }

    @media (min-width: 1100px) {
        height: 512px;
    }

    @media (min-width: 1200px) {
        margin: 200px auto 400px;
        padding: 0;
    }

    &:before {
        content: '';
        position: absolute;
        left: 54px;
        right: 54px;
        top: 0;
        bottom: 0;
        z-index: -1;
        opacity: 0.5;
        background: url(${props => props.imageUrl}) no-repeat right center;
        background-size: contain;

        @media (min-width: 850px) {
            opacity: 1;
        }

        @media (min-width: 1200px) {
            left: 0;
            right: 0;
            padding: 0;
        }
    }

    .spacer {
        margin: 0 0 22px;
        @media (min-width: 600px) {
            margin: 0 0 150px;
        }
        @media (min-height: 800px and min-width: 600px) {
            margin: 0 0 250px;
        }
    }

    h1 {
        align-self: flex-start;
        max-width: 590px;
        font-style: normal;
        font-weight: normal;
        line-height: 48px;
        margin: 0;
        font-size: 32px;

        @media (min-width: 600px) {
            font-size: 40px;
            line-height: 64px;
        }

        & > strong {
            font-weight: 900;
        }
    }
`;

const FactBoxStyle = styled(animated.div)`
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 0 16px;

    @media (min-width: 600px) {
        padding: 0 32px;
    }

    margin: 0 0 250px;
    @media (min-width: 1200px) {
        padding: 0;
    }

    .inner {
        ${cols(4)};
    }
    .number {
        font-weight: 900;
        font-size: 32px;
        line-height: 32px;

        @media (min-width: 600px) {
            font-size: 40px;
            line-height: 64px;
        }
    }

    .content {
        font-size: 18px;
        line-height: 24px;
        font-weight: normal;
        border-left: 8px solid ${colors.black};
        padding-left: 24px;
        margin: 48px 0;

        @media (min-width: 600px) {
            font-size: 24px;
            line-height: 32px;
        }
        & > p {
            margin: 0;
        }
        & > small {
            font-size: 14px;
            line-height: 24px;
            line-height: 18px;
            a {
                text-decoration: underline;
                color: inherit;
            }
        }
    }
`;

const H2 = styled.h2`
    font-weight: 900;
    font-size: 32px;
    line-height: 48px;
    ${cols(8)};
    margin: 400px auto;
    padding: 0 16px;

    @media (min-width: 600px) {
        font-size: 40px;
        line-height: 64px;
        padding: 0 32px;
    }
    @media (min-width: 1200px) {
        padding: 0;
    }
`;

const HowDoesItWorkTop = styled(Link)`
    ${linkReset};
    display: block;
    max-width: 240px;
    color: #6d175f;
    font-weight: 500;
    font-size: 16px;
    padding-left: 40px;
    margin-top: 16px;

    background: url(${purpleArrow}) no-repeat right center, url(${moreinfo}) no-repeat left center;

    &:hover {
        text-decoration: underline;
    }
`;

const HowDoesItWork = styled(Link)`
    ${linkReset};
    color: #6d175f;
    font-weight: 500;
    font-size: 16px;
    margin-top: 40px;

    background: url(${purpleArrow}) no-repeat right center, url(${moreinfo}) no-repeat left center;
    padding-right: 24px;
    padding-left: 40px;
    margin-left: 16px;

    &:hover {
        text-decoration: underline;
    }
`;

const Bottom = styled.div`
    ${cols(8)};
    margin: 400px auto 100px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding: 0 16px;

    @media (min-width: 600px) {
        padding: 0 32px;
    }
    @media (min-width: 1200px) {
        padding: 0;
    }

    .header {
        font-weight: normal;
        font-size: 32px;
        line-height: 48px;

        @media (min-width: 600px) {
            font-size: 40px;
            line-height: 64px;
        }

        & > strong {
            font-weight: 900;
        }
    }
    .cta {
        ${button};
        margin: 80px 0;
        align-self: center;
    }
`;

const Goal5 = styled.a`
    ${linkReset};
    position: absolute;
    left: 0;
    top: 64px;
    z-index: 2;
    width: 70px;
    height: 70px;
    box-shadow: 0px 10px 17px rgba(65, 13, 57, 0.1);

    @media (min-width: 600px) {
        top: 128px;
        width: 100px;
        height: 100px;
    }

    @media (min-width: 800px) {
        position: fixed;
    }

    @media (min-width: 1200px) {
        top: calc(50vh - 100px - 8px);
    }

    img {
        width: 100%;
        height: 100%;
    }
`;

const Goal8 = styled.a`
    ${linkReset};
    position: absolute;
    left: 0;
    top: 144px;
    z-index: 2;
    width: 70px;
    height: 70px;
    box-shadow: 0px 10px 17px rgba(65, 13, 57, 0.1);

    @media (min-width: 600px) {
        top: 240px;
        width: 100px;
        height: 100px;
    }

    @media (min-width: 800px) {
        position: fixed;
    }

    @media (min-width: 1200px) {
        top: calc(50vh + 8px);
    }

    img {
        width: 100%;
        height: 100%;
    }
`;

const FactBox: React.FC<{
    fact: {
        number: number;
        text: SanityLocaleString;
        source: SanityLocaleString;
        sourceUrl: SanityLocaleUrl;
    };
    refIndex: string;
}> = observer(({ fact, refIndex }) => {
    let localizedUrl = useLocalizedString(fact.sourceUrl);

    const { start } = useCountUp({
        ref: refIndex,
        start: 0,
        end: fact.number,
        duration: 1.5,
    });

    const ref = useRef(null);
    const [showing, setShowing] = useState(false);
    let onScreen = useOnScreen(ref, '-30%');
    useEffect(() => {
        if (onScreen && !showing) {
            start();
            setShowing(true);
        }
    }, [onScreen, showing, start]);

    let style = useTrail(2, {
        to: {
            transform: showing ? `translateX(0)` : `translateX(-100px)`,
            opacity: showing ? 1 : 0,
        },
        config: config.slow,
    });

    return (
        <FactBoxStyle ref={ref}>
            <div className="inner">
                <animated.div className="number" style={style[0]}>
                    <span id={refIndex} />
                </animated.div>
                <animated.div className="content" style={style[0]}>
                    <p>
                        <I18nStr>{fact.text}</I18nStr>
                    </p>
                    {fact.sourceUrl ? (
                        <small>
                            <> </>
                            <a href={localizedUrl} rel="noopener noreferrer" target="_blank">
                                <I18nStr>{fact.source}</I18nStr>
                            </a>
                        </small>
                    ) : (
                        <small>
                            <I18nStr>{fact.source}</I18nStr>
                        </small>
                    )}
                </animated.div>
            </div>
        </FactBoxStyle>
    );
});

const Facts2Style = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    margin: 250px 0;
    flex-direction: column;

    padding: 0 16px;

    @media (min-width: 600px) {
        flex-direction: row;
        padding: 0 32px;
    }
    @media (min-width: 1200px) {
        padding: 0;
    }

    .left {
        margin-right: 16px;
        background: url(${arrowBig}) no-repeat top 16px left;
        min-height: 70px;
        @media (min-width: 600px) {
            ${cols(2)};
        }
    }
    .right {
        ${cols(4)};
    }

    h3 {
        font-weight: 900;
        font-size: 32px;
        line-height: 48px;
        margin: 0;
        @media (min-width: 600px) {
            font-size: 40px;
            line-height: 64px;
        }
    }

    p {
        font-size: 18px;
        line-height: 24px;
        @media (min-width: 600px) {
            font-size: 24px;
            line-height: 32px;
        }
    }

    small {
        display: block;
        font-weight: 300;
        font-size: 14px;
    }
`;

const Fact2Box: React.FC<{
    title: SanityLocaleString;
    text: SanityLocaleString;
    source: SanityLocaleString;
    sourceUrl: SanityLocaleUrl;
}> = observer(({ title, text, source, sourceUrl }) => {
    let localizedUrl = useLocalizedString(sourceUrl);

    const ref = useRef(null);
    const [showing, setShowing] = useState(false);
    let onScreen = useOnScreen(ref, '-30%');
    useEffect(() => {
        if (onScreen && !showing) {
            setShowing(true);
        }
    }, [onScreen, showing]);

    const arrowRef = useSpringRef();
    const fadeRef = useSpringRef();

    let arrowStyle = useSpring({
        from: {
            transform: `translateX(-70vw)`,
        },
        to: { transform: showing ? `translateX(0)` : `translateX(-70vw)` },
        config: { mass: 1, tension: 280, friction: 60 },
        delay: 200,
        ref: arrowRef,
    });

    let fadeStyle = useTrail(3, {
        from: { opacity: 0 },
        to: { opacity: showing ? 1 : 0 },
        config: config.molasses,
        delay: 200,
        ref: fadeRef,
    });

    useChain([fadeRef, arrowRef]);

    return (
        <Facts2Style ref={ref}>
            <animated.div className="left" style={arrowStyle} />
            <animated.div className="right">
                <animated.h3 style={fadeStyle[0]}>
                    <I18nStr>{title}</I18nStr>
                </animated.h3>
                <animated.p style={fadeStyle[1]}>
                    <I18nStr>{text}</I18nStr>
                </animated.p>
                <animated.small style={fadeStyle[2]}>
                    {sourceUrl ? (
                        <small>
                            <a href={localizedUrl} rel="noopener noreferrer" target="_blank">
                                <I18nStr>{source}</I18nStr>
                            </a>
                        </small>
                    ) : (
                        <small>
                            <I18nStr>{source}</I18nStr>
                        </small>
                    )}
                </animated.small>
            </animated.div>
        </Facts2Style>
    );
});

const KeyReferenceDocument: React.FC<{ document: any }> = observer(({ document }) => {
    return <a href={useLocalizedString(document.url)}>{useLocalizedString(document.title)}</a>;
});

const KeyReferenceDocuments: React.FC<{}> = observer(() => {
    let rootStore = useStore();
    let landingPage = rootStore.landingPageStore.landingPage;

    return (
        <StyledKeyReferenceDocuments>
            <h2>
                <I18nStr>{landingPage.keyReferenceDocumentsTitle}</I18nStr>
            </h2>
            <ul>
                {landingPage.keyReferenceDocuments.map((document, index) => (
                    <li key={index}>
                        <KeyReferenceDocument document={document} />
                    </li>
                ))}
            </ul>
        </StyledKeyReferenceDocuments>
    );
});

const StyledKeyReferenceDocuments = styled.div`
    ${cols(8)};
    margin: 100px auto 400px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding: 0 16px;

    @media (min-width: 600px) {
        padding: 0 32px;
    }
    @media (min-width: 1200px) {
        padding: 0;
    }
    font-size: 14px;

    h2 {
        font-weight: bold;
        font-size: 15px;
        margin: 0;
    }

    a {
        color: inherit;
    }
`;

const Home = observer(() => {
    let rootStore = useStore();
    let landingPage = rootStore.landingPageStore.landingPage;
    let generalTexts = rootStore.generalTextsStore.generalTexts;

    let scrollToLearn = useLocalizedString(generalTexts.scrollToLearn);
    let landingTopText = useLocalizedBlockObject(landingPage.landingTopText);
    let bottomText = useLocalizedBlockObject(landingPage.bottomText);

    let heroImage =
        landingPage.landingImage &&
        sanity.urlFor(landingPage.landingImage).width(696).height(1024).url();
    return (
        <div>
            <ScrollToTopOnMount />
            <Goal5 href="https://sustainabledevelopment.un.org/sdg5" target="_blank">
                <img src={goal5} alt="Sustainable Development Goal 5" />
            </Goal5>
            <Goal8 href="https://sustainabledevelopment.un.org/sdg8" target="_blank">
                <img src={goal8} alt="Sustainable Development Goal 8" />
            </Goal8>
            <HeroBackground />
            <Hero imageUrl={heroImage || ''}>
                <h1>
                    <BlockContent blocks={landingTopText} />
                </h1>
                <HowDoesItWorkTop to="/how">
                    <I18nStr>{landingPage.howLink}</I18nStr>
                </HowDoesItWorkTop>
                <div className="spacer" />
                <StyledScrollDownDiv
                    onClick={() => {
                        window.scroll({
                            top: 800,
                            left: 0,
                            behavior: 'smooth',
                        });
                    }}
                >
                    {scrollToLearn}
                    <StyledScrollDownButtonWrapper>
                        <StyledScrollDownButton>
                            <ScrollDownIcon />
                        </StyledScrollDownButton>
                    </StyledScrollDownButtonWrapper>
                </StyledScrollDownDiv>
            </Hero>

            {landingPage.landingNumberFacts ? (
                landingPage.landingNumberFacts.map((fact, idx) => (
                    <FactBox key={idx} fact={fact} refIndex={idx.toString()} />
                ))
            ) : (
                <div style={{ height: 1000 }} />
            )}
            <H2>
                <I18nStr>{landingPage.howText}</I18nStr>
            </H2>
            {landingPage.landingHows ? (
                landingPage.landingHows.map((fact, idx) => <Fact2Box key={idx} {...fact} />)
            ) : (
                <div style={{ height: 1000 }} />
            )}
            <Bottom>
                <div className="header">
                    <BlockContent blocks={bottomText} />
                </div>
                <HowDoesItWork to="/how">
                    <I18nStr>{landingPage.howLink}</I18nStr>
                </HowDoesItWork>
                <Link className="cta" to="/s/list">
                    <I18nStr>{landingPage.landingCta}</I18nStr>
                </Link>
            </Bottom>
            <KeyReferenceDocuments />
        </div>
    );
});

const StyledScrollDownDiv = styled.div`
    cursor: pointer;
    align-self: center;
    display: flex;
    flex-direction: column;
    ${p2};
    line-height: 32px;
`;

const StyledScrollDownButtonWrapper = styled.div`
    position: relative;
    align-self: center;
`;

const StyledScrollDownButton = styled.button`
    position: fixed;
    transform: translate(-50%);
    ${buttonReset};
`;

export default Home;

function ScrollDownIcon() {
    return (
        <svg
            aria-hidden
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                stroke="#6D175F"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M7 13l5 5 5-5M7 6l5 5 5-5"
            ></path>
        </svg>
    );
}
