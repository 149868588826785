import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import * as BlockContent from '@sanity/block-content-to-react';

import { useLockBodyScroll } from '../lib/hooks';
import { cols, p1, h2, h3, colors, buttonSecondary, button } from '../styles';
import { useStore } from '../StoreProvider';
import { urlFor } from '../api/sanity';
import Checkbox from '../components/Checkbox';
import Slider from '../components/Slider';
import { I18nStr, useLocalizedBlockObject, useLocalizedString } from '../i18n/localization';

const Wrapper = styled.div`
    z-index: 3;
    display: flex;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2);
`;

const CloseBtn = styled.button`
    ${buttonSecondary};
    display: flex;
    position: absolute;
    right: 16px;
    top: 16px;
    @media (min-width: 600px) {
        right: 32px;
        top: 32px;
    }
`;

const Inner = styled.div`
    ${cols(10)};
    padding: 0 16px;
    position: relative;
    margin: auto;
    background: #fff;
    border-radius: 4px;
    width: 100%;
    max-height: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    box-shadow: 0px 0px 17px rgba(19, 5, 17, 0.1);

    & > .content {
        ${cols(8)};
        margin: 60px auto;

        & > .heading {
        }
    }

    h1 {
        ${h2};
        margin: 5px 0;
    }
`;

const HeroImage = styled.img`
    width: calc(100% + 32px);
    margin: 0 -16px;
    object-fit: cover;
`;

const Heading = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;

    @media (max-width: 768px) {
        justify-content: flex-start;
        flex-wrap: wrap;
    }

    .heading_main {
        flex-grow: 1;
        max-width: 590px;
        margin-right: 16px;
    }

    .heading_title {
        ${h3};
        white-space: nowrap;
    }

    .heading_selector {
        & > .heading_title {
            margin: 0 0 43px;
        }
        margin-right: 10px;
        flex-basis: 105px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 30px;

        &:last-child {
            @media (max-width: 600px) {
                margin-left: auto;
            }
        }

        @media (max-width: 768px) {
            margin-top: 18px;
            margin-right: 16px;
        }

        button {
            display: block;
        }
    }

    .completed_box {
        margin: 0;
        align-self: flex-end;
    }
`;

const Notes = styled.div`
    max-width: 590px;
    margin: 60px 0 0;

    label {
        display: flex;
        ${h3};
    }

    textarea {
        width: 100%;
        height: 208px;
        border: none;
        border-radius: 4px;
        background: ${colors.formGrey};
        ${p1};
        margin: 15px 0;
        padding: 16px;
    }

    button {
        ${button};
        float: right;
    }
`;

const BlockContentWrapper = styled.div`
    max-width: 590px;
    margin: 0 0 200px;
    h2 {
        ${h2};
        margin: 38px 0 23px;
    }
    a {
        font-weight: 500;
        color: #003f9d;
        &:hover {
            color: #0353cc;
        }
    }
`;

export type CloseModal = () => void;

const TaskModalInner: React.FC<{
    investigationId: string;
    taskId: string;
    closeModal: CloseModal;
}> = observer(({ investigationId, taskId, closeModal }) => {
    let rootStore = useStore();
    let config = rootStore.configStore.config;
    let text = rootStore.generalTextsStore.generalTexts;

    let taskMaybe = rootStore.taskStore.tasks.get(taskId);
    let investigationMaybe = rootStore.investigationStore.investigations.get(investigationId);

    let status =
        taskMaybe && investigationMaybe && investigationMaybe.taskStatus.get(taskMaybe._key);

    let [notesInput, setNotesInput] = useState('');
    let statusNotes = status && status.notes;
    useEffect(() => {
        if (statusNotes) {
            setNotesInput(statusNotes);
        }
    }, [statusNotes]);

    if (taskMaybe === undefined || investigationMaybe === undefined) {
        return <div>Not Found</div>;
    }

    let investigation = investigationMaybe;
    let task = taskMaybe;

    function saveNotes() {
        investigation.setTaskNotes(task._key, notesInput);
    }

    let imageSrc = task.image
        ? urlFor(task.image).width(1198).height(368).url()
        : `https://source.unsplash.com/collection/3785974/1198x368/?${task._key}`;

    return (
        <Inner>
            <CloseBtn onClick={closeModal}>
                <I18nStr>{text.close}</I18nStr>
            </CloseBtn>
            <HeroImage src={imageSrc || ''} width={1198} height={368} />
            <div className="content">
                <Heading>
                    <div className="heading_main">
                        <div className="heading_title">
                            <I18nStr>{config.youShould}</I18nStr>
                        </div>
                        <h1>
                            <I18nStr>{task.ltitle}</I18nStr>
                        </h1>
                    </div>
                    <div className="heading_selector">
                        <div className="heading_title">
                            <I18nStr>{config.relationsApplicableHeader}</I18nStr>
                        </div>
                        <Slider
                            className="slider"
                            enabled={!((status && status.notApplicable) || false)}
                            onChange={enabled => {
                                investigation.setNotApplicable(task._key, !enabled);
                            }}
                        />
                    </div>
                    <div className="heading_selector">
                        <div className="heading_title">
                            <I18nStr>{config.done}</I18nStr>
                        </div>
                        <div className="completed_box">
                            <Checkbox
                                disabled={(status && status.notApplicable) || false}
                                checked={(status && status.done) || false}
                                onChange={checked => {
                                    investigation.setDone(task._key, checked);
                                }}
                            />
                        </div>
                    </div>
                </Heading>
                <Notes>
                    <label htmlFor="notes">
                        <I18nStr>{config.notes}</I18nStr>
                    </label>
                    <textarea
                        id="notes"
                        placeholder={useLocalizedString(config.notesHelperText)}
                        value={notesInput}
                        onChange={ev => setNotesInput(ev.target.value)}
                    />
                    <button
                        disabled={status ? notesInput === status.notes : notesInput === ''}
                        onClick={() => saveNotes()}
                    >
                        <I18nStr>{text.save}</I18nStr>
                    </button>
                    <div style={{ clear: 'both' }} />
                </Notes>
                <BlockContentWrapper>
                    <BlockContent blocks={useLocalizedBlockObject(task.ltext)} />
                </BlockContentWrapper>
            </div>
        </Inner>
    );
});
const TaskModal: React.FC<{ match: any; history: any }> = ({ match, history }) => {
    useLockBodyScroll();
    let { investigationId, relationId, taskId } = match.params;

    let closeModal = () => history.push(`/s/she/${investigationId}/r/${relationId}`);

    return (
        <Wrapper
            onClick={ev => {
                if ((ev.target as HTMLDivElement).closest(Inner) !== null) {
                    // Don't close modal if the click is inside Inner
                    return;
                }
                closeModal();
            }}
        >
            <TaskModalInner
                investigationId={investigationId}
                taskId={taskId}
                closeModal={closeModal}
            />
        </Wrapper>
    );
};

export default TaskModal;
