import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { colors, buttonReset, p2 } from '../styles';
import questionmark from '../img/questionmark.svg';
import questionmarkSmall from '../img/questionmarkSmall.svg';
import { I18nStr, SanityLocaleText } from '../i18n/localization';

interface Props {
    content: SanityLocaleText | string;
    className?: string;
    left?: boolean;
}

const MoreInfoStyle = styled.div`
    ${p2};
    position: relative;
    text-transform: none;
    font-variant: initial;
`;

const Button = styled.button`
    ${buttonReset};
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 12px;
    background: url(${questionmarkSmall}) no-repeat center center, ${colors.actionPurple};

    @media (min-width: 900px) {
        width: 24px;
        height: 24px;
        background: url(${questionmark}) no-repeat center center, ${colors.actionPurple};
    }
`;

const Content = styled.div<{ left: boolean }>`
    width: 348px;
    padding: 16px;
    position: absolute;
    top: 50px;
    ${props => (props.left ? `right: -17px` : `left: -17px`)};
    box-shadow: 0px 0px 17px rgba(19, 5, 17, 0.1);

    & > div {
        z-index: 1;
        position: relative;
    }

    &:before {
        position: absolute;
        content: '';
        top: 0;
        ${props => (props.left ? `right: 0` : `left: 0`)};
        width: 100%;
        height: 100%;

        background-color: #fff;
        border-radius: 4px;
        z-index: 1;
    }

    &:after {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        background: #fff;
        transform: rotate(45deg);
        top: -10px;
        ${props => (props.left ? `right: 20px;` : `left: 20px;`)};
        box-shadow: 0px 0px 17px rgba(19, 5, 17, 0.1);
        z-index: 0;
    }
`;

const MoreInfo: React.FC<Props> = ({ content, className, left = false }) => {
    let [showInfo, setShowInfo] = useState(false);
    let node = useRef<any>();

    useEffect(() => {
        let handleClick = (e: any) => {
            if (node.current === undefined) {
                return;
            }
            if (node.current.contains(e.target)) {
                // inside click
                return;
            }
            setShowInfo(false);
        };
        // add when mounted
        document.addEventListener('mousedown', handleClick);

        // return function to be called when unmounted
        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, []);

    return (
        <MoreInfoStyle ref={node} className={className}>
            <Button onClick={() => setShowInfo(!showInfo)} />
            {showInfo && (
                <Content left={left}>
                    <div>
                        <I18nStr>{content}</I18nStr>
                    </div>
                </Content>
            )}
        </MoreInfoStyle>
    );
};

export default MoreInfo;
