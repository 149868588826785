import React, { useLayoutEffect, useState, useEffect, useRef, RefObject } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import {useLocation} from "react-router-dom";

// https://usehooks.com/useLockBodyScroll/
export function useLockBodyScroll() {
    useLayoutEffect(() => {
        // Get original body overflow

        const originalStyle = window.getComputedStyle(document.body).overflow;

        // Prevent scrolling on mount

        document.body.style.overflow = 'hidden';

        // Re-enable scrolling when component unmounts

        return () => {
            document.body.style.overflow = originalStyle;
        };
    }, []); // Empty array ensures effect is only run on mount and unmount
}

export function useInterval(callback: any, delay: any) {
    const savedCallback = useRef<any>();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

export function useMeasure(): [any, { left: number; top: number; width: number; height: number }] {
    const ref = useRef<HTMLElement>();
    const [bounds, set] = useState({ left: 0, top: 0, width: 0, height: 0 });
    const [ro] = useState(() => new ResizeObserver(([entry]) => set(entry.contentRect)));
    useEffect(() => {
        if (ref.current) ro.observe(ref.current);
        return () => ro.disconnect();
    }, []);
    return [{ ref }, bounds];
}

// https://usehooks.com/useMedia/
export function useMedia<T>(queries: string[], values: T[], defaultValue: T): T {
    // Array containing a media query list for each query
    const mediaQueryLists = queries.map(q => window.matchMedia(q));

    // Function that gets value based on matching media query
    const getValue = () => {
        // Get index of first media query that matches
        const index = mediaQueryLists.findIndex(mql => mql.matches);
        // Return related value or defaultValue if none
        return typeof values[index] !== 'undefined' ? values[index] : defaultValue;
    };

    // State and setter for matched value
    const [value, setValue] = useState(getValue);

    useEffect(() => {
        // Event listener callback
        // Note: By defining getValue outside of useEffect we ensure that it has ...
        // ... current values of hook args (as this hook callback is created once on mount).
        const handler = () => setValue(getValue);
        // Set a listener for each media query with above handler as callback.
        mediaQueryLists.forEach(mql => mql.addListener(handler));
        // Remove listeners on cleanup
        return () => mediaQueryLists.forEach(mql => mql.removeListener(handler));
    }, []); // Empty array ensures effect is only run on mount and unmount

    return value;
}

// From https://usehooks.com/useOnScreen/
export function useOnScreen(ref: RefObject<HTMLElement>, rootMargin = '0px') {
    // State and setter for storing whether element is visible
    const [isIntersecting, setIntersecting] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                // Update our state when observer callback fires

                setIntersecting(entry.isIntersecting);
            },

            {
                rootMargin,
            }
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        let oldCurrent = ref.current;

        return () => {
            if (oldCurrent) {
                observer.unobserve(oldCurrent);
            }
        };
    }, []); // Empty array ensures that effect is only run on mount and unmount

    return isIntersecting;
}

// https://usehooks.com/useWindowSize/
export function useWindowSize() {
    const isClient = typeof window === 'object';

    function getSize() {
        return {
            width: isClient ? window.innerWidth : 0,

            height: isClient ? window.innerHeight : 0,
        };
    }

    const [windowSize, setWindowSize] = useState(getSize);

    useEffect(() => {
        if (!isClient) {
            return;
        }

        function handleResize() {
            setWindowSize(getSize());
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty array ensures that effect is only run on mount and unmount

    return windowSize;
}

// A custom hook that builds on useLocation to parse
// the query string for you.
// https://v5.reactrouter.com/web/example/query-parameters
export function useQueryParams() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}
