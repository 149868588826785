import { types, flow, getParentOfType } from 'mobx-state-tree';
import { SanityImageSource } from '@sanity/image-url/lib/types/types';

import sanity from '../api/sanity';
import { RootStore } from './RootStore';
import { SanityLocaleString } from '../i18n/localization';

type FooterLinkElement =
    | { _type: 'heading'; text: SanityLocaleString }
    | { _type: 'link'; url: string; text: SanityLocaleString; blank: boolean }
    | { _type: 'internalLink'; slug: { current: string }; text: SanityLocaleString }
    | { _type: 'internalHardcodedLink'; reference: string; text: SanityLocaleString };

export interface Footer {
    links?: FooterLinkElement[];
    footerLogos?: Array<SanityImageSource & { href: string }>;
    footerAttribution?: SanityLocaleString;
}

let defaultFooter: Footer = {
    links: [],
    footerLogos: [],
    footerAttribution: undefined,
};

export const FooterStore = types
    .model('FooterStore', {
        footer: types.optional(types.frozen<Footer>(), defaultFooter),
    })
    .actions(self => ({
        loadData: flow(function* loadData() {
            try {
                let footer = yield sanity.fetch(
                    `*[_type=="footerDocument"][0]{
                        ...,
                        links[]{
                            ...,
                            _type == "internalLink" => {
                                "slug": @.reference->slug
                            }
                        }
                    }`
                ) as Promise<Footer>;

                self.footer = {
                    ...defaultFooter,
                    ...footer,
                };
            } catch (err: any) {
                getParentOfType(self, RootStore).setError(err, 'FooterStoreLoadData');
            }
        }),
    }));
