import React, { useReducer } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { Link, Route, useParams } from 'react-router-dom';

import { useStore } from '../StoreProvider';
import { ITask, IRelation } from '../stores/TaskStore';
import { Config } from '../stores/ConfigStore';
import { IInvestigation } from '../stores/InvestigationStore';
import { h2, h3, buttonReset, linkReset, colors, buttonStyle } from '../styles';
import plus from '../img/plus.svg';
import minus from '../img/minus.svg';
import exclamationMark from '../img/exclamationmark.svg';

import PageWrapper from '../components/PageWrapper';
import TaskModal from '../components/TaskModal';
import Checkbox from '../components/Checkbox';
import Slider from '../components/Slider';
import ProgressText from '../components/ProgressText';
import MoreInfo from '../components/MoreInfo';
import { OverallProgressRelation } from '../components/OverallProgress';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import { InvestigationHeader } from '../components/InvestitigationHeader';
import { ExpandableRichText } from '../components/ExpandableRichText';
import {
    I18nStr,
    SanityLocaleString,
    SanityLocaleText,
    useLocalizedBlockObject,
    useLocalizedString,
} from '../i18n/localization';

const H1 = styled.h1`
    ${h2};
    margin: 0 0 9px;
    font-weight: 300;

    strong {
        font-weight: normal;
    }
    a {
        ${linkReset};
        &:hover {
            text-decoration: underline;
        }
    }
`;

const TaskTable = styled.div`
    width: 100%;
    max-width: 590px;
    background: #fff;
    padding: 42px 0 32px;
    border-radius: 0 0 2px 2px;

    & > .headers {
        ${h3};

        @media (max-width: 600px) {
            & > div {
                flex-grow: 1;
            }
        }

        & > div:nth-last-child(2) {
            padding-left: 0;
        }

        & > div:nth-last-child(1) {
            padding-right: 0;
        }
    }

    & > div {
        padding: 0 16px;
        display: flex;

        .completed {
            padding-left: 0;
            padding-right: 30px;
        }

        & > div {
            padding: 16px 8px;
        }

        & > div:nth-child(1) {
            padding-left: 0;
        }

        & > div:nth-last-child(1) {
            text-align: right;
            display: flex;
            justify-content: flex-end;
        }

        @media (min-width: 601px) {
            & > div {
                padding: 16px 8px;
                width: 130px;
                flex-grow: 0;
            }

            & > div:nth-child(2) {
                width: 80px;
            }

            & > div:nth-child(1) {
                flex-grow: 1;
            }
        }
    }

    & > .task {
        @media (max-width: 600px) {
            flex-wrap: wrap;
        }
        & > div {
            border-bottom: 1px solid #e3e3e3;

            @media (max-width: 600px) {
                width: 50%;
            }
        }

        & > div > a {
            ${linkReset};
        }

        & > .slider {
            display: flex;
            justify-content: flex-end;
        }

        @media (max-width: 600px) {
            & > .title {
                width: 100%;
                border: none;
            }
        }

        &.disabled > .title {
            opacity: 0.5;
        }
        &:hover {
            background: ${colors.hoverGrey};
        }
    }
`;

const InactiveTab = styled.button<{ focused: boolean; finished: boolean }>`
    ${buttonReset};
    text-align: left;
    display: block;
    background: url(${plus}) no-repeat right 16px center,
        ${props => (props.finished ? '#AAC6EF' : colors.formGrey)};
    padding: 0 ${props => (props.focused ? 16 - 4 : 16)}px;
    line-height: 48px;
    max-width: 590px;
    width: 100%;
    font-weight: 500;
    border-radius: 2px;

    ${props =>
        props.focused &&
        `
        border-left: 4px solid ${colors.orange};
    `}

    &:hover {
        background: url(${plus}) no-repeat right 16px center,
            ${props => (props.finished ? '#BCCDE6' : colors.hoverGrey)};
    }
`;

const ActiveTab = styled.div`
    box-shadow: 0px 0px 17px rgba(19, 5, 17, 0.1);
    width: 100%;
    max-width: 590px;
    border-radius: 2px;

    .heading {
        ${buttonReset};
        text-align: left;
        display: block;
        background: url(${minus}) no-repeat right 16px center, ${colors.orange};
        padding: 0 16px;
        line-height: 48px;
        width: 100%;
        font-weight: 500;
        border-radius: 2px 2px 0 0;
    }
`;

const Categories = styled.div`
    display: grid;
    width: 100%;
    margin: 16px 0;
    justify-items: start;
    align-items: start;
    justify-content: start;
    max-width: 590px;
    grid-template-columns: 1fr 1fr;

    @media (min-width: 900px) {
        grid-gap: 16px;
        max-width: 873px;
        grid-template-columns: minmax(343px, 590px) 1fr minmax(auto, 56px);
    }
    & > .actions_header {
        grid-column-start: span 1;
    }
    & > .progress_header {
        grid-column-start: span 2;
        display: none;

        @media (min-width: 900px) {
            display: flex;
        }
    }
    & > ${ActiveTab}, & > ${InactiveTab} {
        grid-column: 1 / span 2;
        @media (min-width: 900px) {
            grid-column: 1 / span 1;
        }
    }
    & > .more_info {
        justify-self: end;
        margin: 8px 16px 24px;

        @media (min-width: 900px) {
            margin: 0;
            /* Need to add some margin because we cannot use align-items: center because we don't have
               subgrids */
            margin-top: 12px;
            justify-self: center;
        }
    }
    & > .progress {
        justify-self: start;
        margin: 8px 16px 24px;
        @media (min-width: 900px) {
            margin: 0;
            /* Need to add some margin because we cannot use align-items: center because we don't have
               subgrids */
            margin-top: 12px;
            justify-self: start;
        }
    }
    & > .header {
        ${h3};
        padding-top: 48px;
    }
`;

const LearnMore = styled.div`
    ${buttonStyle};
    color: ${colors.actionPurple};
    padding: 0 24px 0 0;
    margin: 19px 0;
    background: url(${exclamationMark}) no-repeat center right;
    display: inline-block;
`;

const BusinessConduct = styled.div`
    font-size: 16px;
    line-height: 24px;
    opacity: 0.5;
    grid-column: 1 / span 2;
    @media (min-width: 900px) {
        grid-column: 1 / span 3;
    }
`;

interface InvestigationRelationProps {
    investigationId: string;
    relationId: string;
}

enum TabType {
    Assess = 'assess',
    IntegrateAndAct = 'integrateAndAct',
    Track = 'track',
    Communicate = 'communicate',
}

function tabTitle(tab: TabType, config: Config): SanityLocaleString | string {
    switch (tab) {
        case TabType.Assess:
            return config.assessHeader;
        case TabType.IntegrateAndAct:
            return config.integrateAndActHeader;
        case TabType.Track:
            return config.trackHeader;
        case TabType.Communicate:
            return config.communicateHeader;
        default:
            return '';
    }
}

function tabHelp(tab: TabType, config: Config): SanityLocaleText | string {
    switch (tab) {
        case TabType.Assess:
            return config.assessHelp;
        case TabType.IntegrateAndAct:
            return config.integrateAndActHelp;
        case TabType.Track:
            return config.trackHelp;
        case TabType.Communicate:
            return config.communicateHelp;
        default:
            return '';
    }
}

interface SetActiveTab {
    type: 'SET_ACTIVE_TAB';
    activeTab: TabType;
}

interface CloseTabs {
    type: 'CLOSE_TABS';
}

interface State {
    activeTab: TabType | null;
}

type Action = SetActiveTab | CloseTabs;

const reducer: React.Reducer<State, Action> = (state, action) => {
    switch (action.type) {
        case 'SET_ACTIVE_TAB':
            return { activeTab: action.activeTab };
        case 'CLOSE_TABS':
            return { activeTab: null };
        default:
            return state;
    }
};

const initialState = {
    activeTab: null,
};

function formatTabIndex(index: number) {
    return index.toString();
}

const TabIndex = styled.span<{ focused: boolean }>`
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    padding-right: 16px;
    ${props =>
        props.focused &&
        `
        font-weight: bold;
        font-size: 16px;
    `}
`;

interface TasksProps {
    tabType: TabType;
    relation: IRelation;
    tasks: ITask[];
    active: boolean;
    dispatch: React.Dispatch<Action>;
    investigation: IInvestigation;
    index: number;
    focused: boolean;
    finished: boolean;
}
const Tasks: React.FC<TasksProps> = observer(
    ({ tabType, tasks, active, dispatch, investigation, relation, index, focused, finished }) => {
        let config = useStore().configStore.config;

        if (!active) {
            return (
                <InactiveTab
                    focused={focused}
                    finished={finished}
                    onClick={() => dispatch({ type: 'SET_ACTIVE_TAB', activeTab: tabType })}
                >
                    <TabIndex focused={focused}>{formatTabIndex(index)}</TabIndex>{' '}
                    <I18nStr>{tabTitle(tabType, config)}</I18nStr>
                </InactiveTab>
            );
        }

        return (
            <ActiveTab>
                <button onClick={() => dispatch({ type: 'CLOSE_TABS' })} className="heading">
                    <TabIndex focused={focused}>{formatTabIndex(index)}</TabIndex>{' '}
                    <I18nStr>{tabTitle(tabType, config)}</I18nStr>
                </button>
                <TaskTable>
                    <div className="headers">
                        <div>
                            <I18nStr>{config.task}</I18nStr>
                        </div>
                        <div title="Applicable">
                            <I18nStr>{config.relationsApplicableHeader}</I18nStr>
                        </div>
                        <div>
                            <I18nStr>{config.done}</I18nStr>
                        </div>
                    </div>
                    {tasks.map(task => {
                        let status = investigation.taskStatus.get(task._key);
                        let disabled = (status && status.notApplicable) || false;
                        return (
                            <div key={task._key} className={`task ${disabled && 'disabled'}`}>
                                <div className="title">
                                    <Link
                                        to={`/s/she/${investigation.id}/r/${relation._key}/t/${task._key}`}
                                    >
                                        <div>
                                            <I18nStr>{task.ltitle}</I18nStr>
                                        </div>
                                        {task.ltext && (
                                            <LearnMore>
                                                <I18nStr>{config.expandTask}</I18nStr>
                                            </LearnMore>
                                        )}
                                    </Link>
                                </div>
                                <div className="slider">
                                    <Slider
                                        enabled={!((status && status.notApplicable) || false)}
                                        onChange={enabled => {
                                            investigation.setNotApplicable(task._key, !enabled);
                                        }}
                                    />
                                </div>
                                <div className="completed">
                                    <Checkbox
                                        disabled={disabled}
                                        checked={(status && status.done) || false}
                                        onChange={checked => {
                                            investigation.setDone(task._key, checked);
                                        }}
                                    />
                                </div>
                            </div>
                        );
                    })}
                </TaskTable>
            </ActiveTab>
        );
    }
);

function tasksStats(tasks: ITask[], investigation: IInvestigation) {
    let taskStatus = investigation.taskStatus;

    let numberOfTasks = tasks.length;
    let numberOfDoneTasks = tasks.filter(
        task => taskStatus.get(task._key) && taskStatus.get(task._key)!.done
    ).length;
    let numberOfNaTasks = tasks.filter(
        task => taskStatus.get(task._key) && taskStatus.get(task._key)!.notApplicable
    ).length;

    return {
        numberOfDoneTasks,
        numberOfTasks,
        numberOfNaTasks,
    };
}

const TasksProgress: React.FC<{
    tasks: ITask[];
    investigation: IInvestigation;
    className?: string;
}> = observer(({ className, tasks, investigation }) => {
    let data = tasksStats(tasks, investigation);

    return <ProgressText {...data} className={className} />;
});

const InvestigationRelation: React.FC<{}> = observer(() => {
    let [state, dispatch] = useReducer(reducer, initialState);
    let { investigationId, relationId } = useParams<InvestigationRelationProps>();

    let rootStore = useStore();
    let config = rootStore.configStore.config;
    let commitment = rootStore.commitmentStore.commitment;
    let taskStore = rootStore.taskStore;
    let investigationStore = rootStore.investigationStore;
    let text = rootStore.generalTextsStore.generalTexts;

    let investigation = investigationStore.investigations.get(investigationId);
    let relation = taskStore.relations.get(relationId);

    if (investigation === undefined || relation === undefined) {
        return <div>Not Found</div>;
    }

    let industry = investigation.industry;
    return (
        <React.Fragment>
            <ScrollToTopOnMount />
            <Route path="/s/she/:investigationId/r/:relationId/t/:taskId" component={TaskModal} />
            <PageWrapper>
                <InvestigationHeader
                    smallHeader
                    investigation={investigation}
                    backlink={`/s/she/${investigationId}`}
                    backlinkText={useLocalizedString(text.back)}
                />
                <StyledTopSectionDiv>
                    <StyledHeaderDiv>
                        <H1>
                            <Link to={`/s/she/${investigationId}`}>
                                <I18nStr>{industry.lname}</I18nStr>
                            </Link>
                            <ArrowIcon />
                            <strong>
                                <I18nStr>{relation.lname}</I18nStr>
                            </strong>
                        </H1>
                        <ExpandableRichText content={useLocalizedBlockObject(relation.ltext)} />
                    </StyledHeaderDiv>
                    <OverallProgressRelation investigation={investigation!} relation={relation!} />
                </StyledTopSectionDiv>
                <p>
                    {useLocalizedString(config.investigationRelationshipIntro).replace(
                        'RELATIONSHIP',
                        useLocalizedString(relation.lname)
                    )}
                </p>
                <Categories>
                    <div className="header actions_header">
                        <I18nStr>{config.actions}</I18nStr>
                    </div>
                    <div className="header progress_header">
                        <I18nStr>{config.taskProgression}</I18nStr>
                    </div>
                    <BusinessConduct>
                        <TabIndex focused={false}>1</TabIndex>
                        <I18nStr>{commitment.subHeader}</I18nStr>
                    </BusinessConduct>
                    {[
                        TabType.Assess,
                        TabType.IntegrateAndAct,
                        TabType.Track,
                        TabType.Communicate,
                    ].map((tab, index, array) => {
                        let lastStats =
                            index > 0
                                ? tasksStats(relation![array[index - 1]], investigation!)
                                : undefined;
                        let thisStats = tasksStats(relation![tab], investigation!);
                        let lastFinished =
                            lastStats === undefined
                                ? true
                                : lastStats.numberOfDoneTasks >=
                                  lastStats.numberOfTasks - lastStats.numberOfNaTasks;
                        let thisFinished =
                            thisStats.numberOfDoneTasks >=
                            thisStats.numberOfTasks - thisStats.numberOfNaTasks;
                        let focused = lastFinished && !thisFinished;
                        return (
                            <React.Fragment key={tab}>
                                <Tasks
                                    tabType={tab}
                                    relation={relation!}
                                    tasks={relation![tab]}
                                    active={state.activeTab === tab}
                                    dispatch={dispatch}
                                    investigation={investigation!}
                                    index={index + 2}
                                    focused={focused}
                                    finished={thisFinished}
                                />
                                <TasksProgress
                                    className="progress"
                                    tasks={relation![tab]}
                                    investigation={investigation!}
                                />
                                <MoreInfo
                                    left={true}
                                    className="more_info"
                                    content={tabHelp(tab, config)}
                                />
                            </React.Fragment>
                        );
                    })}
                </Categories>
            </PageWrapper>
        </React.Fragment>
    );
});

export default InvestigationRelation;

const StyledTopSectionDiv = styled.div`
    display: flex;
    flex-direction: column;
    margin: 32px 0 0;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 40px;

    @media (min-width: 1000px) {
        flex-direction: row;
    }
`;

const StyledHeaderDiv = styled.div`
    flex-grow: 1;
    width: 100%;
    max-width: 800px;
    margin-bottom: 16px;
    @media (min-width: 1000px) {
        max-width: 590px;
    }
`;

function ArrowIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            aria-hidden
            viewBox="0 0 16 16"
        >
            <path
                stroke="#A5DEA4"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.334 8h9.333M8 3.333L12.667 8 8 12.666"
            ></path>
        </svg>
    );
}
