import axios, { AxiosRequestConfig } from 'axios';
import firebase from 'firebase/compat/app';

async function getAuthorizationHeader() {
    let currentUser = firebase.auth().currentUser;
    if (currentUser === null) {
        return '';
    }
    let idToken = await currentUser.getIdToken();

    return `Bearer ${idToken}`;
}

export const apiUrl = process.env.NODE_ENV === 'production' ? '/api' : 'http://localhost:3001/api';

const shedilApi = axios.create({
    baseURL: apiUrl,
    timeout: 120000,
    headers: {},
    withCredentials: true,
});

shedilApi.interceptors.request.use(
    async function (config: AxiosRequestConfig) {
        if (config.headers === undefined) {
            config.headers = {};
        }
        config.headers['Authorization'] = await getAuthorizationHeader();
        return config;
    },
    function (error) {
        console.log('Request error: ', error);
        return Promise.reject(error);
    }
);

export default shedilApi;
