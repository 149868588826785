import { types } from 'mobx-state-tree';
import firebase from 'firebase/compat/app';

export const CurrentUser = types.model('CurrentUser', {
    uid: types.identifier,
    displayName: types.string,
});

export const AuthStore = types
    .model('AuthStore', {
        currentUser: types.maybe(CurrentUser),
        isLoading: true,
    })
    .actions(self => ({
        setUserLoggedIn(user: firebase.User) {
            self.isLoading = false;
            self.currentUser = CurrentUser.create({
                uid: user.uid,
                displayName: user.displayName || '',
            });
        },
        setUserLoggedOut() {
            self.isLoading = false;
            self.currentUser = undefined;
        },
    }))
    .actions(self => ({
        startAuthChecker() {
            firebase.auth().onAuthStateChanged(function (user) {
                if (user) {
                    self.setUserLoggedIn(user);
                } else {
                    self.setUserLoggedOut();
                }
            });
        },
    }));
