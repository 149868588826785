import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { format } from 'date-fns';
import './no-reach-tabs-styles.css';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@reach/tabs';

import { linkReset, colors, button, h3, cols } from '../styles';
import { useStore } from '../StoreProvider';
import { IInvestigation } from '../stores/InvestigationStore';
import PageWrapper from '../components/PageWrapper';
import ProgressText from '../components/ProgressText';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import { ShedilMenu } from '../components/ShedilMenu';
import { I18nStr, useLocalizedString } from '../i18n/localization';

const LinkButton = styled(Link)`
    ${button};
`;

const LinkButtonWrapper = styled.div`
    display: flex;
    max-width: 550px;
    flex-direction: column;
    align-items: stretch;

    @media (min-width: 550px) {
        align-items: flex-start;
        flex-direction: row;
    }
    p {
        margin: 0 24px 0 0;
    }
    a {
        white-space: nowrap;
        margin-top: 16px;

        @media (min-width: 550px) {
            margin: 0;
        }
    }
`;

const StyledTabs = styled(Tabs)`
    margin-top: 64px;

    [data-reach-tab-list] {
        display: flex;
    }

    [data-reach-tab] {
        display: inline-block;
        border: none;
        padding: 0;
        padding-bottom: 4px;
        margin: 0;
        margin-right: 24px;
        border-bottom: 4px solid transparent;
        background: none;
        color: inherit;
        font: inherit;
        cursor: pointer;
        -webkit-appearance: none;
        -moz-appearance: none;

        &:hover {
            color: ${colors.actionBlueHover};
        }
    }

    [data-reach-tab][data-selected] {
        border-bottom-color: currentColor;
        color: ${colors.actionBlue};
    }
`;

const InvestigationLink = styled(Link)`
    ${linkReset};
    display: block;
    background: ${colors.formGrey};
    padding: 0 16px;
    line-height: 48px;
    max-width: 469px;
    width: 100%;
    font-weight: 500;
    border-radius: 2px;

    &:hover {
        background: ${colors.hoverGrey};
    }
`;

const ActivatedTime = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    display: flex;
    align-items: center;

    @media (min-width: 800px) {
        font-size: 16px;
        line-height: 16px;
    }

    svg {
        margin-right: 8px;
    }
`;

const Investigations = styled.div`
    ${cols(7)};
    display: grid;
    margin: 16px 0;
    grid-template-columns: 121px 1fr;
    align-items: center;
    @media (min-width: 800px) {
        grid-gap: 16px 0;
        grid-template-columns: minmax(340px, 500px) 1fr 32px auto 32px auto;
    }

    .progress_header {
        display: none;

        @media (min-width: 800px) {
            grid-column: 4;
            display: block;
        }
    }

    .activated_header {
        display: none;

        @media (min-width: 800px) {
            grid-column: 6;
            display: block;
        }
    }

    .industry_header {
        grid-column: 1 / span 2;
        margin-bottom: 16px;
        @media (min-width: 800px) {
            margin: 0;
            grid-column: 1 / span 1;
        }
    }

    ${ActivatedTime} {
        margin-bottom: 24px;
        margin-top: 8px;
        @media (min-width: 800px) {
            margin: 0;
            grid-column: 6;
        }
    }

    & > .progress {
        margin-bottom: 24px;
        margin-top: 8px;
        margin-left: 16px;

        @media (min-width: 800px) {
            margin: 0;
            grid-column: 4;
        }
    }

    & > .header {
        ${h3};
        padding-top: 43px;
    }
`;

const InvestigationProgress: React.FC<{
    investigation: IInvestigation;
    className?: string;
}> = observer(({ investigation, className }) => {
    let taskStatus = investigation.taskStatus;
    let data = investigation.industry.relations
        .map(relation => {
            let tasks = relation.assess
                .concat(relation.integrateAndAct.slice())
                .concat(relation.track.slice())
                .concat(relation.communicate.slice());

            let numberOfTasks = tasks.length;
            let numberOfDoneTasks = tasks.filter(
                task => taskStatus.get(task._key) && taskStatus.get(task._key)!.done
            ).length;
            let numberOfNaTasks = tasks.filter(
                task => taskStatus.get(task._key) && taskStatus.get(task._key)!.notApplicable
            ).length;

            return { numberOfTasks, numberOfDoneTasks, numberOfNaTasks };
        })
        .reduce(
            (a, b) => ({
                numberOfTasks: a.numberOfTasks + b.numberOfTasks,
                numberOfDoneTasks: a.numberOfDoneTasks + b.numberOfDoneTasks,
                numberOfNaTasks: a.numberOfNaTasks + b.numberOfNaTasks,
            }),
            { numberOfTasks: 0, numberOfDoneTasks: 0, numberOfNaTasks: 0 }
        );

    return <ProgressText className={className} {...data} />;
});

const InvestigationList: React.FC<{
    investigations: Map<string, IInvestigation[]>;
    inactive?: boolean;
}> = observer(({ investigations, inactive = false }) => {
    const rootStore = useStore();
    const taskStore = rootStore.taskStore;
    const config = rootStore.configStore.config;
    const industries = Array.from(investigations.keys());
    industries.sort((a, b) =>
        useLocalizedString(taskStore.industries.get(a)!.lname).localeCompare(
            useLocalizedString(taskStore.industries.get(b)!.lname)
        )
    );
    return (
        <StyledInvestigations inactive={inactive}>
            {industries.map((industryId, index) => (
                <React.Fragment key={industryId}>
                    <div className="header industry_header">
                        <I18nStr>{taskStore.industries.get(industryId)!.lname}</I18nStr>
                    </div>
                    <div className="header progress_header">
                        {index === 0 ? <I18nStr>{config.progress}</I18nStr> : ''}
                    </div>
                    <div className="header activated_header">
                        {index === 0 ? <I18nStr>{config.activated}</I18nStr> : ''}
                    </div>
                    {investigations!.get(industryId)!.map(investigation => (
                        <React.Fragment key={investigation.id}>
                            <StyledDiv>
                                <InvestigationLink to={`/s/she/${investigation.id}`}>
                                    {investigation.name}
                                </InvestigationLink>
                                <ShedilMenu
                                    investigation={investigation}
                                    redirectOnDelete={false}
                                />
                            </StyledDiv>
                            <InvestigationProgress
                                className="progress"
                                investigation={investigation}
                            />
                            <ActivatedTime>
                                <CalendarIcon /> {format(investigation.createdAt, 'yyyy/MM/dd')}
                            </ActivatedTime>
                        </React.Fragment>
                    ))}
                </React.Fragment>
            ))}
        </StyledInvestigations>
    );
});

const StyledInvestigations = styled(Investigations)<{ inactive: boolean }>`
    ${({ inactive }) => (inactive ? 'opacity: 0.7' : '')}
`;

const StyledDiv = styled.div`
    grid-column: 1 / span 2;
    display: flex;

    @media (min-width: 800px) {
        grid-column: 1 / span 1;
    }
`;

const InvestigationLists = observer(() => {
    let rootStore = useStore();
    let config = rootStore.configStore.config;

    let investigationStore = rootStore.investigationStore;

    let investigations = investigationStore.activeInvestigationsByIndustry;

    return (
        <div>
            <ScrollToTopOnMount />
            <PageWrapper>
                <LinkButtonWrapper>
                    {investigations.size > 0 ? (
                        <p>
                            <I18nStr>{config.createNewSelect}</I18nStr>
                        </p>
                    ) : (
                        <p>
                            <I18nStr>{config.createNewNoSelect}</I18nStr>
                        </p>
                    )}
                    <LinkButton to="/s/create">
                        <I18nStr>{config.createShedilButton}</I18nStr>
                    </LinkButton>
                </LinkButtonWrapper>
                <StyledTabs>
                    <TabList>
                        <Tab>
                            <I18nStr>{config.onGoing}</I18nStr>
                        </Tab>
                        <Tab>
                            <I18nStr>{config.inactive}</I18nStr>
                        </Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <InvestigationList
                                investigations={investigationStore.activeInvestigationsByIndustry}
                            />
                        </TabPanel>
                        <TabPanel>
                            <InvestigationList
                                investigations={investigationStore.inActiveInvestigationsByIndustry}
                                inactive
                            />
                        </TabPanel>
                    </TabPanels>
                </StyledTabs>
            </PageWrapper>
        </div>
    );
});

export default InvestigationLists;

function CalendarIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 16 16"
        >
            <g stroke="#130511" strokeLinecap="round" strokeLinejoin="round" opacity="0.7">
                <path d="M12.667 2.667H3.333C2.597 2.667 2 3.263 2 4v9.333c0 .737.597 1.333 1.333 1.333h9.334c.736 0 1.333-.596 1.333-1.333V4c0-.737-.597-1.333-1.333-1.333zM10.667 1.333V4M5.333 1.333V4M2 6.667h12"></path>
            </g>
        </svg>
    );
}
