import React, { useState, useEffect, FC } from 'react';
import BlockContent from '@sanity/block-content-to-react';

import sanity, { projectId, dataset, urlFor } from '../api/sanity';
import styled from 'styled-components';
import { colors, h1, h2, h3 } from '../styles';
import { Link, useParams } from 'react-router-dom';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import { observer } from 'mobx-react-lite';
import { useLocalizedBlockObject } from '../i18n/localization';

const serializers = {
    types: {
        image: ({ node }: any) => {
            return (
                <ImageWrapper>
                    <img
                        alt=""
                        style={{ maxWidth: node.size || '100%' }}
                        src={urlFor(node).width(952).url() || undefined}
                    />
                </ImageWrapper>
            );
        },
    },
    marks: {
        internalLink: ({ mark, children }: any) => {
            const { slug = {} } = mark;
            const href = `/content/${slug.current}`;
            return <Link to={href}>{children}</Link>;
        },
        internalHardcodedLink: ({ mark, children }: any) => {
            const { reference = {} } = mark;
            const href = `${reference}`;
            return <Link to={href}>{children}</Link>;
        },
        link: ({ mark, children }: any) => {
            // Read https://css-tricks.com/use-target_blank/
            const { blank, href } = mark;
            return blank ? (
                <a href={href} target="_blank" rel="noopener noreferrer">
                    {children}
                </a>
            ) : (
                <a href={href}>{children}</a>
            );
        },
    },
};

const ImageWrapper = styled.div`
    text-align: center;
`;

const CustomPage: FC<{ negative?: boolean }> = observer(({ negative = false }) => {
    let { slug } = useParams<{ slug: string }>();
    const [data, setData] = useState<any | null>(null);
    let localizedBody = useLocalizedBlockObject(data?.body);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        (async () => {
            let res = await sanity.fetch(`*[_type=="contentPage" && slug.current =="${slug}"][0]{
                ...,
                body{
                  ...,
                  es[] {
                      ...,
                    markDefs[]{
                        ...,
                        _type == "internalLink" => {
                          "slug": @.reference->slug
                        }
                      }
                  },
                  gb[] {
                      ...,
                    markDefs[]{
                        ...,
                        _type == "internalLink" => {
                          "slug": @.reference->slug
                        }
                      }
                  }

                }
              }`);

            if (!res) {
                setError(new Error('404 - Not found'));
            }

            setData(res);
        })().catch(err => setError(err));
    }, [slug]);

    if (error) {
        return <div>{error.toString()}</div>;
    }

    if (!data) {
        return <div>Loading...</div>;
    }

    let imageSrc = data.image && urlFor(data.image).width(696).height(1024).url();

    return (
        <>
            <ScrollToTopOnMount />
            <StyledWrapperDiv negative={negative}>
                {imageSrc && <StyledImg alt="" src={imageSrc} />}
                <StyledBlockContent
                    blocks={localizedBody}
                    serializers={serializers}
                    projectId={projectId}
                    dataset={dataset}
                />
            </StyledWrapperDiv>
        </>
    );
});

const StyledWrapperDiv = styled.div<{ negative: boolean }>`
    background: ${({ negative }) => (negative ? '#2A1D28' : 'inherit')};
    position: relative;
`;

const StyledImg = styled.img`
    width: 348px;
    height: 512px;
    position: fixed;
    top: calc(50vh - 256px);
    filter: opacity(50%);

    right: calc(50vw - 174px);
    @media (max-width: 600px) {
        display: none;
    }

    @media (min-width: 900px) {
        right: calc(50vw - 174px - 242px);
    }
`;

const StyledBlockContent = styled(BlockContent)<{ negative: boolean }>`
    color: ${({ negative }) => (negative ? colors.neutral : 'inherit')};
    max-width: 757px;
    margin: 0 auto;
    padding: 48px 24px 200px;
    font-size: 17px;
    line-height: 1.5;
    position: relative;

    a {
        color: inherit;
    }

    h1 {
        ${h1};
    }

    h2 {
        ${h2};
        margin-top: 1.7em;
    }

    h3 {
        ${h3};
        margin-top: 1.7em;
        font-size: 17px;
    }
`;

export default CustomPage;
