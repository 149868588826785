import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import arrowWhite from '../img/arrow-white.svg';
import { colors, cols, p2, linkReset } from '../styles';
import { observer } from 'mobx-react-lite';
import { useStore } from '../StoreProvider';
import * as sanity from '../api/sanity';
import { I18nStr } from '../i18n/localization';

const Wrapper = styled.div`
    background: ${colors.black};
    color: #fff;

    .inner {
        ${cols(10)};
        padding: 56px 16px 26px;
        margin: 0 auto;
    }
`;

const Heading = styled.div`
    font-weight: 300;
    font-size: 16px;
    line-height: 32px;
    & strong {
        font-weight: 500;
    }
`;

const Links = styled.div`
    margin: 70px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    & > a {
        ${linkReset};
        display: block;
        margin: 4px 0;
        background: url(${arrowWhite}) no-repeat right center;
        padding: 0 46px 0 0;
        &:hover {
            text-decoration: underline;
        }
    }

    & > div {
        display: block;
        margin: 24px 0 8px;
        font-weight: 500;
    }
`;

const Bottom = styled.div`
    ${p2};
    display: flex;
    & > .copy {
        ${cols(1)};
    }
`;

const Logos = styled.div`
    margin: 70px 0;

    & > a,
    & > img {
        display: inline;
        margin-right: 24px;
    }
`;

const Footer = observer(() => {
    let footer = useStore().footerStore.footer;

    return (
        <Wrapper>
            <div className="inner">
                <Heading>
                    She<strong>Dil</strong>
                </Heading>
                <Links>
                    {footer.links?.map((el, idx) => {
                        if (el._type === 'link') {
                            const { blank, url, text } = el;
                            return blank ? (
                                <a key={idx} href={url} target="_blank" rel="noopener noreferrer">
                                    <I18nStr>{text}</I18nStr>
                                </a>
                            ) : (
                                <a key={idx} href={url}>
                                    <I18nStr>{text}</I18nStr>
                                </a>
                            );
                        }
                        if (el._type === 'heading') {
                            return (
                                <div key={idx}>
                                    <I18nStr>{el.text}</I18nStr>
                                </div>
                            );
                        }
                        if (el._type === 'internalLink') {
                            const { slug, text } = el;
                            const href = `/content/${slug.current}`;
                            return (
                                <Link to={href} key={idx}>
                                    <I18nStr>{text}</I18nStr>
                                </Link>
                            );
                        }
                        if (el._type === 'internalHardcodedLink') {
                            const { reference, text } = el;
                            const href = `${reference}`;
                            return (
                                <Link to={href} key={idx}>
                                    <I18nStr>{text}</I18nStr>
                                </Link>
                            );
                        }
                        return null;
                    })}
                </Links>
                <Logos>
                    {footer.footerLogos?.map((logo, idx) => {
                        let src = sanity.urlFor(logo).height(50).url() || '';
                        if (logo.href) {
                            return (
                                <a href={logo.href} key={idx}>
                                    <img alt="" src={src} />
                                </a>
                            );
                        } else {
                            return <img alt="" src={src} key={idx} />;
                        }
                    })}
                </Logos>
                <Bottom>
                    <div className="copy">&copy; {new Date().getFullYear()}</div>
                    <I18nStr>{footer.footerAttribution}</I18nStr>
                </Bottom>
            </div>
        </Wrapper>
    );
});

export default Footer;
