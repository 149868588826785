import { types, flow, getParentOfType } from 'mobx-state-tree';

import sanity from '../api/sanity';
import { RootStore } from './RootStore';
import {
    SanityLocaleBlockContent,
    SanityLocaleString,
    SanityLocaleText,
} from '../i18n/localization';

export interface HowPage {
    title: SanityLocaleString | undefined;
    text: SanityLocaleBlockContent | undefined;
    video: string | undefined;
    stopText: SanityLocaleBlockContent | undefined;
    ctaText: SanityLocaleText | undefined;
    ctaButton: SanityLocaleString | undefined;
}

let defaultHowPage: HowPage = {
    title: undefined,
    text: undefined,
    video: undefined,
    stopText: undefined,
    ctaText: undefined,
    ctaButton: undefined,
};

export const HowPageStore = types
    .model('HowPageStore', {
        howPage: types.optional(types.frozen<HowPage>(), defaultHowPage),
    })
    .actions(self => ({
        loadData: flow(function* loadData() {
            try {
                let howPage = yield sanity.fetch(`*[_type=="howPage"][0]`) as Promise<HowPage>;

                self.howPage = {
                    ...defaultHowPage,
                    ...howPage,
                };
            } catch (err: any) {
                getParentOfType(self, RootStore).setError(err, 'HowPageStoreLoadData');
            }
        }),
    }));
