import React, { Component } from 'react';

import AppRouter from './AppRouter';
import { GlobalStyle } from './styles';

class App extends Component {
    render() {
        return (
            <React.Fragment>
                <GlobalStyle />
                <AppRouter />
            </React.Fragment>
        );
    }
}

export default App;
