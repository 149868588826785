import PageWrapper from "../components/PageWrapper";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {button} from "../styles";
import shedilApi from "../api/shedil";
import Spinner from "../components/Spinner";
import {I18nStr} from "../i18n/localization";
import {useStore} from "../StoreProvider";
import {useQueryParams} from "../lib/hooks";
import {Link, useHistory} from "react-router-dom";

const ConfirmBtn = styled.button`
    ${button};
`;

const LinkButton = styled(Link)`
    ${button};
`;

const FlexContainer = styled.div`
  display: flex;
  margin: 8px 0;
  gap: 8px;
  align-items: baseline;
`;

const Preferences = () => {
  const [emailConsent, setEmailConsent] = useState(false);
  const [initialInputValue, setInitialInputValue] = useState(false);
  const [loading, setLoading] = useState(true);
  const [fetchComplete, setFetchComplete] = useState(false);
  let params = useQueryParams();
  const firstLogin = params.get('firstLogin');
  const history = useHistory();
  
  const rootStore = useStore();
  let texts = rootStore.generalTextsStore.generalTexts;
  
  const fetchConsent = async () => {
    setLoading(true);
    const consentResponse = await shedilApi.get('/v1/contact');
    const consent = consentResponse.data.consent;
    setEmailConsent(consent);
    setInitialInputValue(consent);
    setLoading(false);
    setFetchComplete(true);
  }
  
  const saveEmailContactConsent = async () => {
    setLoading(true)
    const response = await shedilApi.post('/v1/contact', {
      emailConsent
    });
    const updatedConsent = response.data.consent;
    setEmailConsent(updatedConsent)
    setInitialInputValue(updatedConsent);
    setLoading(false);
    if (firstLogin) {
      history.push('/s/list');
    }
  }
  
  useEffect(() => {
    if(!fetchComplete) {
      void fetchConsent();
    }
  }, [fetchComplete])
  
  return (
    <PageWrapper>
      <ScrollToTopOnMount />
      <h1><I18nStr>{texts.preferences}</I18nStr></h1>
      
        { loading
          ? <Spinner />
          : (
            <div>
              <FlexContainer>
                <label htmlFor="email-consent"><I18nStr>{texts.allowEmailContact}</I18nStr></label>
                <input type="checkbox" name="email-consent" onChange={(e) => setEmailConsent(e.target.checked)} checked={emailConsent}/>
              </FlexContainer>
              <FlexContainer>
                <ConfirmBtn onClick={saveEmailContactConsent} disabled={emailConsent === initialInputValue}>
                  <I18nStr>{texts.save}</I18nStr>
                </ConfirmBtn>
                {
                  firstLogin && (
                    <LinkButton to="/s/list">
                      <I18nStr>{texts.skip}</I18nStr>
                    </LinkButton>
                  )
                }
              </FlexContainer>
            </div>
          )
        }
    </PageWrapper>
  );
};

export default Preferences;
