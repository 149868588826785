import React, { useContext } from 'react';

import { IRootStore } from './stores/RootStore';

const StoreContext = React.createContext<IRootStore | null>(null);

interface StoreProviderProps {
    value: IRootStore;
}

export const StoreProvider: React.FC<StoreProviderProps> = ({ value, children }) => (
    <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
);

export const useStore = () => useContext(StoreContext)!;
