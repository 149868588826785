import React, { useRef } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import * as BlockContent from '@sanity/block-content-to-react';

import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import { useStore } from '../StoreProvider';
import PageWrapper from './PageWrapper';
import { cols, h2, button, p2 } from '../styles';
import modelLarge from '../img/model.svg';
import modelSmall from '../img/model_small.svg';
import moreinfo from '../img/moreinfo.svg';
import arrow from '../img/arrow.svg';
import { useMedia, useOnScreen } from '../lib/hooks';
import {
    defaultLanguageString,
    I18nStr,
    useLocalizedBlockObject,
    useLocalizedString,
} from '../i18n/localization';

// import * as types from 'styled-components/cssprop'; // Enable the css prop

const Wrapper = styled.div`
    ${cols(6)};

    .model_attribution {
        font-weight: 100;
        text-align: right;
    }
`;

const Header = styled.h2`
    ${h2};
`;

const SubHeader = styled.h2`
    ${h2};
    margin: 38px 0 67px;
`;

const BlockContentWrapper = styled.div`
    h2 {
        ${h2};
    }

    ul {
        list-style-type: none;
        padding: 0;
        margin-top: 40px;
    }

    li {
        background: url(${arrow}) no-repeat left 40px top 23px, #fff;
        padding: 13px 16px 11px 105px;
        margin: 16px 0;
    }
    a {
        font-weight: 500;
        color: #003f9d;
        &:hover {
            color: #0353cc;
        }
    }
`;

const CommitmentCTA = styled.button`
    ${button};
    float: right;
    margin: 80px 0 0;
`;

const Intro = styled.div`
    background: url(${moreinfo}) no-repeat left 12px top 12px, #fff;
    padding: 20px 16px 0;
    margin: 50px 0 60px;

    @media (min-width: 450px) {
        background: url(${moreinfo}) no-repeat left 32px top 12px, #fff;
        padding: 0 16px 0 105px;
    }

    & > p {
        padding: 13px 0 19px;
    }
`;

// // background: ${({ darkMode }) => (darkMode ? colors.black : 'inherit')};

const ModelLegend = styled.ul<{ showProcessText: boolean }>`
    ${p2};
    padding: 0;
    list-style-type: none;
    margin: 0 16px;
    @media (min-width: 600px) {
        display: ${({ showProcessText }) => (showProcessText ? '' : 'none')};
    }
`;

const Model = styled.img`
    width: 100%;
`;

const FadeInWhenOnScreenStyle = styled.div<{ active: boolean }>`
    opacity: ${props => (props.active ? '1.0' : '0.3')};
`;

const FadeInWhenOnScreen: React.FC = ({ children }) => {
    const ref = useRef(null);
    let onScreen = useOnScreen(ref, '-30%');

    return (
        <FadeInWhenOnScreenStyle ref={ref} active={onScreen}>
            {children}
        </FadeInWhenOnScreenStyle>
    );
};

const Commitment: React.FC<{ onCommit: () => void }> = observer(({ onCommit }) => {
    let rootStore = useStore();
    const commitment = rootStore.commitmentStore.commitment;
    let lang = rootStore.language.selectedLanguage;
    let text = useLocalizedBlockObject(commitment.text);

    const model = useMedia(['(min-width: 600px)'], [modelLarge], modelSmall);

    return (
        <div>
            <ScrollToTopOnMount />
            <PageWrapper>
                <Wrapper>
                    <Header>
                        <I18nStr>{commitment.header}</I18nStr>
                    </Header>
                    <SubHeader>
                        <I18nStr>{commitment.subHeader}</I18nStr>
                    </SubHeader>
                    <p>
                        <I18nStr>{commitment.figure}</I18nStr>
                    </p>
                    <Model src={model} alt={useLocalizedString(commitment.figure)} />
                    <ModelLegend showProcessText={lang !== defaultLanguageString}>
                        <li>
                            1. <I18nStr>{commitment.step1}</I18nStr>
                        </li>
                        <li>
                            2. <I18nStr>{commitment.step2}</I18nStr>
                        </li>
                        <li>
                            3. <I18nStr>{commitment.step3}</I18nStr>
                        </li>
                        <li>
                            4. <I18nStr>{commitment.step4}</I18nStr>
                        </li>
                        <li>
                            &rarr; <I18nStr>{commitment.step45}</I18nStr>
                        </li>
                        <li>
                            5. <I18nStr>{commitment.step5}</I18nStr>
                        </li>
                    </ModelLegend>
                    <p className="model_attribution">
                        "OECD Due Diligence Guidance for Responsible Business Conduct", OECD 2018.
                    </p>
                    <Intro>
                        <p>
                            <I18nStr>{commitment.intro}</I18nStr>
                        </p>
                    </Intro>
                    <FadeInWhenOnScreen>
                        <BlockContentWrapper>
                            <BlockContent blocks={text} />
                        </BlockContentWrapper>
                        <CommitmentCTA onClick={onCommit}>
                            <I18nStr>{commitment.cta}</I18nStr>
                        </CommitmentCTA>
                        <div style={{ clear: 'both' }} />
                    </FadeInWhenOnScreen>
                </Wrapper>
            </PageWrapper>
        </div>
    );
});

export default Commitment;
