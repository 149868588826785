import React from 'react';
import styled from 'styled-components';

import { cols } from '../styles';

const Wrapper = styled.div`
    ${cols(10)};
    margin: 48px auto 200px;

    padding: 0 16px;

    @media (min-width: 600px) {
        padding: 0 32px;
    }

    @media (min-width: 1200px) {
        padding: 0 32px 0 ${105 + 16}px;
    }
`;

const PageWrapper: React.FC = ({ children }) => {
    return <Wrapper>{children}</Wrapper>;
};

export default PageWrapper;
