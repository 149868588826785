import { types, flow, getParentOfType } from 'mobx-state-tree';

import sanity from '../api/sanity';
import { SanityLocaleString, SanityLocaleText } from '../i18n/localization';
import { RootStore } from './RootStore';

export interface Config {
    createShedilHeader: SanityLocaleString | string;
    createShedilButton: SanityLocaleString | string;
    createShedilLabel: SanityLocaleString | string;
    createShedilPlaceholder: SanityLocaleString | string;
    createShedilChooseSector: SanityLocaleString | string;
    createShedilChooseButton: SanityLocaleString | string;
    done: SanityLocaleString | string;
    relations: SanityLocaleString | string;
    relationshipInfo: SanityLocaleString | string;
    relationsPogressHeader: SanityLocaleString | string;
    relationsApplicableHeader: SanityLocaleString | string;

    investigationRelationshipsIntro: SanityLocaleText | string;
    investigationRelationshipIntro: SanityLocaleText | string;
    assessHeader: SanityLocaleString | string;
    assessHelp: SanityLocaleText | string;
    integrateAndActHeader: SanityLocaleString | string;
    integrateAndActHelp: SanityLocaleText | string;
    trackHeader: SanityLocaleString | string;
    trackHelp: SanityLocaleText | string;
    communicateHeader: SanityLocaleString | string;
    communicateHelp: SanityLocaleText | string;
    createNewSelect: SanityLocaleText | string;
    createNewNoSelect: SanityLocaleText | string;
    inactive: SanityLocaleString | string;
    onGoing: SanityLocaleString | string;
    activated: SanityLocaleString | string;
    progress: SanityLocaleString | string;
    actions: SanityLocaleString | string;
    taskProgression: SanityLocaleString | string;
    expandTask: SanityLocaleString | string;
    youShould: SanityLocaleString | string;
    notes: SanityLocaleString | string;
    notesHelperText: SanityLocaleString | string;
    overallProgress: SanityLocaleString | string;
    businessRelationProgress: SanityLocaleString | string;
    noTaskApplicable: SanityLocaleString | string;
    dismissedAsNotApplicable: SanityLocaleString | string;
    task: SanityLocaleString | string;
}

let defaultConfig: Config = {
    createShedilHeader: '',
    createShedilLabel: '',
    createShedilPlaceholder: '',
    done: '',
    relationshipInfo: '',
    investigationRelationshipsIntro: '',
    investigationRelationshipIntro: '',
    assessHelp: '',
    integrateAndActHelp: '',
    trackHelp: '',
    communicateHelp: '',
    createNewSelect: '',
    createNewNoSelect: '',
    inactive: 'Inactive SheDils',
    onGoing: 'Ongoing SheDils',
    createShedilButton: 'Create New',
    activated: 'Activated',
    progress: 'Progress',
    relations: 'Relations',
    actions: 'Actions',
    taskProgression: 'Task Progression',
    assessHeader: 'Assess',
    integrateAndActHeader: 'Integrate and Act',
    trackHeader: 'Track',
    communicateHeader: 'Communicate',
    expandTask: 'Key checkpoints',
    createShedilChooseSector: '',
    createShedilChooseButton: 'Choose',
    relationsPogressHeader: 'Detailed Progress',
    relationsApplicableHeader: 'Applicable',
    youShould: 'You should',
    notes: 'Notes',
    notesHelperText:
        'Key notes of your analysis, plans, priorities, actions taken/not taken and reasons for doing so.',
    overallProgress: 'Overall progress',
    businessRelationProgress: 'Business Relation progress',
    noTaskApplicable: 'No tasks applicable',
    dismissedAsNotApplicable: 'dismissed as not applicable',
    task: 'Task',
};

export const ConfigStore = types
    .model('ConfigStore', {
        config: types.optional(types.frozen<Config>(), defaultConfig),
    })
    .actions(self => ({
        loadData: flow(function* loadData() {
            try {
                let config = yield sanity.fetch(
                    `*[_type=="config" && _id=="default-config"][0]`
                ) as Promise<Config>;

                self.config = {
                    ...defaultConfig,
                    ...config,
                };
            } catch (err: any) {
                getParentOfType(self, RootStore).setError(err, 'ConfigStoreLoadData');
            }
        }),
    }));
