import React, { FC } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { IInvestigation } from '../stores/InvestigationStore';
import { h2, linkReset, p1 } from '../styles';
import { ShedilMenu } from './ShedilMenu';

export const InvestigationHeader: FC<{
    investigation: IInvestigation;
    backlink: string;
    backlinkText: string;
    smallHeader?: boolean;
}> = ({ investigation, backlink, backlinkText, smallHeader = false }) => {
    return (
        <StyledWrapper>
            <Link to={backlink}>
                <BackIcon /> {backlinkText}
            </Link>
            <StyledInnerDiv smallHeader={smallHeader}>
                <h1>{investigation.name}</h1>
                <ShedilMenu investigation={investigation} redirectOnDelete={true} />
            </StyledInnerDiv>
        </StyledWrapper>
    );
};

function BackIcon() {
    return (
        <svg
            aria-hidden
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 16 16"
        >
            <path
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12.666 8H3.333M8 12.667L3.333 8 8 3.333"
            ></path>
        </svg>
    );
}

const StyledWrapper = styled.div`
    a {
        ${linkReset};
        font-size: 16px;
        font-weight: 300;
        display: flex;
        align-items: center;
        position: relative;
        margin-bottom: 52px;

        @media (min-width: 600px) {
            left: -24px;
        }

        svg {
            margin-right: 8px;
        }
    }
`;

const StyledInnerDiv = styled.div<{ smallHeader: boolean }>`
    margin-bottom: 46px;
    display: flex;
    justify-content: space-between;
    max-width: 574px;
    h1 {
        ${props => (props.smallHeader ? p1 : h2)}
        margin: 0;
    }
`;
