import { types, flow, getParentOfType } from 'mobx-state-tree';

import sanity from '../api/sanity';
import {
    SanityLocaleBlockContent,
    SanityLocaleString,
    SanityLocaleText,
} from '../i18n/localization';
import { RootStore } from './RootStore';

interface Commitment {
    text: SanityLocaleBlockContent | undefined;
    cta: SanityLocaleString | string;
    subHeader: SanityLocaleString | string;
    header: SanityLocaleString | string;
    intro: SanityLocaleText | string;
    figure: SanityLocaleString | string;
    step1: SanityLocaleString | string;
    step2: SanityLocaleString | string;
    step3: SanityLocaleString | string;
    step4: SanityLocaleString | string;
    step45: SanityLocaleString | string;
    step5: SanityLocaleString | string;
}

let defaultCommitment: Commitment = {
    text: undefined,
    cta: '',
    subHeader: '',
    header: '',
    intro: '',
    figure: '',
    step1: '',
    step2: '',
    step3: '',
    step4: '',
    step45: '',
    step5: '',
};

export const CommitmentStore = types
    .model('CommitmentStore', {
        commitment: types.optional(types.frozen<Commitment>(), defaultCommitment),
    })
    .actions(self => ({
        loadData: flow(function* loadData() {
            try {
                let commitment = yield sanity.fetch(
                    `*[_type=="commitment"][0]`
                ) as Promise<Commitment>;

                self.commitment = {
                    ...defaultCommitment,
                    ...commitment,
                };
            } catch (err: any) {
                getParentOfType(self, RootStore).setError(err, 'CommitmentStoreLoadData');
            }
        }),
    }));
