import { types, flow, getParentOfType } from 'mobx-state-tree';

import sanity from '../api/sanity';
import { RootStore } from './RootStore';
import { SanityImageSource } from '@sanity/image-url/lib/types/types';
import {
    SanityLocaleBlockContent,
    SanityLocaleString,
    SanityLocaleUrl,
} from '../i18n/localization';

export interface LandingNumberFact {
    number: number;
    text: SanityLocaleString;
    source: SanityLocaleString;
    sourceUrl: SanityLocaleUrl;
}

export interface LandingHow {
    title: SanityLocaleString;
    text: SanityLocaleString;
    source: SanityLocaleString;
    sourceUrl: SanityLocaleUrl;
}

type KeyReferenceDocument = {
    title: SanityLocaleString;
    url: SanityLocaleUrl;
};

export interface LandingPage {
    landingImage?: SanityImageSource;
    landingTopText?: SanityLocaleBlockContent;
    howLink: SanityLocaleString | undefined;
    landingNumberFacts: LandingNumberFact[] | undefined;
    howText: SanityLocaleString | undefined;
    landingHows: LandingHow[] | undefined;
    landingCta?: SanityLocaleString;
    bottomText?: SanityLocaleBlockContent;
    keyReferenceDocumentsTitle: SanityLocaleString | string;
    keyReferenceDocuments: KeyReferenceDocument[];
}

let defaultLandingPage: LandingPage = {
    landingImage: undefined,
    landingTopText: undefined,
    howLink: undefined,
    landingNumberFacts: undefined,
    howText: undefined,
    landingHows: undefined,
    landingCta: undefined,
    bottomText: undefined,
    keyReferenceDocumentsTitle: 'Key reference documents',
    keyReferenceDocuments: [],
};

export const LandingPageStore = types
    .model('LandingPageStore', {
        landingPage: types.optional(types.frozen<LandingPage>(), defaultLandingPage),
    })
    .actions(self => ({
        loadData: flow(function* loadData() {
            try {
                let landingPage = yield sanity.fetch(
                    `*[_type=="landingPage"][0]`
                ) as Promise<LandingPage>;

                self.landingPage = {
                    ...defaultLandingPage,
                    ...landingPage,
                };
            } catch (err: any) {
                getParentOfType(self, RootStore).setError(err, 'LandingPageStoreLoadData');
            }
        }),
    }));
