import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

// Initialize Firebase
const config = {
    apiKey: 'AIzaSyAR555VETv4AO3Vw3dkb373m9Tvk2bq4bw',
    authDomain: 'shedil.firebaseapp.com',
    databaseURL: 'https://shedil.firebaseio.com',
    projectId: 'shedil',
    storageBucket: '',
    messagingSenderId: '454970772085',
};
firebase.initializeApp(config);

export const db = firebase.firestore();
