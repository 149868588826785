import React from 'react';
import styled, { keyframes } from 'styled-components';
import { space } from 'styled-system';

import spinner from '../img/spinner.svg';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Wrapper = styled.div`
    display: flex;
    margin: 100px 0;
    justify-content: center;
    align-items: center;
    ${space};

    img {
        animation: ${rotate} 2s linear infinite;
    }
`;

const Spinner = (props: any) => (
    <Wrapper {...props}>
        <img src={spinner} alt="spinner" />
    </Wrapper>
);

export default Spinner;
