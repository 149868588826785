import React from 'react';
import styled from 'styled-components';

import { p2 } from '../styles';

interface Props {
    value: number;
    max: number;
    caption: string;
    color?: string;
    className?: string;
}

const Wrapper = styled.div`
    ${p2};
`;

const ProgressBarStyle = styled.div<{ pct: number; color: string }>`
    width: ${props => props.pct}%;
    transition: width 0.5s ease-out;
    max-width: 500px;
    height: 16px;
    background: linear-gradient(to right, #c4c4c4, ${props => props.color});
    border-radius: 2px;
`;

const ProgressBar: React.FC<Props> = ({ value, max, caption, color, className }) => {
    let realPct = (value * 100) / max;
    let displayPct = realPct;
    if (displayPct < 1) {
        displayPct = 1;
    }
    return (
        <Wrapper className={className}>
            <ProgressBarStyle pct={displayPct} color={color || '#a5dea4'} />
            {Math.round(realPct)} % {caption}
        </Wrapper>
    );
};

export default ProgressBar;
